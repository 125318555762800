import { useMemo } from 'react';
import { Box, Container, Typography, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { BigNumber } from 'bignumber.js';

import { PILOT_LOGO } from '../../constants';
import {
  ButtonIndicator,
  InputMax,
  WalletConnectDialog,
  CTooltip,
  DisableElements,
  RowAlignCenter,
  CGrid,
  CheckListItem,
  TokenDialog,
  ConfirmationDialog,
} from '../../components';
import useStyles from './styles';
import { useAutoSelect, useBurn } from '../../hooks';
import { usePilotToken, useBurnModals } from '../../state/burn/hooks';
import { useLoading, useGas } from '../../state/application/hooks';
import { LOADING } from '../../utils/enums';
import { formatAmount, trunc, formatDollarAmount } from '../../utils/formating';

const Add: React.FC<{}> = ({}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { account } = useWeb3React();
  const _xl = useMediaQuery(theme.breakpoints.down('xl'));
  const _lg = useMediaQuery(theme.breakpoints.down('lg'));
  const _md = useMediaQuery(theme.breakpoints.down('md'));
  const _sm = useMediaQuery(theme.breakpoints.down('sm'));

  const { burnLabel, burnDisable, burnAction, burnDollarValue, returnAmount, returnPercentage } =
    useBurn();
  const {
    connectModal,
    tokenSelectModal,
    confirmationModal,
    toggleConnectModal,
    toggleTokenSelectModal,
    toggleConfirmationModal,
  } = useBurnModals();
  const { pilotToken } = usePilotToken();
  const { dappLoading } = useLoading();
  //const { returnDollarValue } = useAutoSelect();
  const { gasPrice } = useGas();

  const pilotBalance = useMemo<string | undefined>(
    () => pilotToken.balance && formatAmount(pilotToken.balance, pilotToken.decimals),
    [pilotToken.balance]
  );

  const paddingRight = !_sm && _md ? { paddingRight: 24 } : undefined;
  const paddingLeft = !_md && (_lg || _xl) ? { paddingLeft: 24 } : undefined;

  return (
    <>
      <WalletConnectDialog open={connectModal} close={() => toggleConnectModal(false)} />
      <TokenDialog
        open={tokenSelectModal}
        close={() => toggleTokenSelectModal(false)}
        ListItem={CheckListItem}
        type='burn'
      />
      <ConfirmationDialog
        open={confirmationModal}
        close={() => toggleConfirmationModal(false)}
        type='burn'
      />
      <Container
        style={{
          paddingRight: paddingLeft?.paddingLeft || paddingRight?.paddingRight,
        }}
        disableGutters={!_sm}
        maxWidth='sm'
      >
        <Box className={classes.conatiner}>
          <Typography style={{ marginTop: 8, textAlign: 'center' }} variant='h5'>
            Index Fund
          </Typography>
          <Typography style={{ marginTop: 8, textAlign: 'left' }} variant='h6'>
            Pilot to burn
          </Typography>

          <DisableElements disabled={!account || dappLoading === LOADING.BURN || gasPrice === ''}>
            <Box mt={'15px'} />
            <CGrid
              containerClassName={[classes.selectTokenInputMaxContainer].join(' ')}
              item01Props={{
                xs: 4,
              }}
              item02Props={{
                xs: 8,
              }}
              elements01={
                <Box display='flex' ml={'15px'} padding='0px 5px' alignItems='center'>
                  <img src={PILOT_LOGO} width={30} height={30} />
                  <Typography
                    style={{
                      fontSize: '1rem',
                      fontWeight: 500,
                      margin: '0px 8px',
                    }}
                    variant='body2'
                    color='primary'
                    className={classes.typoPri}
                  >
                    PILOT
                  </Typography>
                </Box>
              }
              elements02={
                <InputMax style={{ fontWeight: 500 }} className={classes.inputMaxContainer} />
              }
            />

            <Box mt={'10px'} />
            <RowAlignCenter
              containerClassName={classes.rac}
              elements01={
                <Box>
                  {pilotBalance !== undefined &&
                    (parseFloat(pilotBalance) > 0 ? (
                      <CTooltip title={pilotBalance}>
                        <Typography
                          variant='body2'
                          style={{ fontSize: '0.8rem', fontWeight: 400 }}
                          className={classes.pilotAmount}
                        >{`Balance: ${trunc(pilotBalance)} Pilot`}</Typography>
                      </CTooltip>
                    ) : (
                      <Typography
                        variant='body2'
                        style={{ fontSize: '0.8rem', fontWeight: 400 }}
                        className={classes.pilotAmount}
                      >{`Balance: ${0} Pilot`}</Typography>
                    ))}
                </Box>
              }
              elements02={
                burnDollarValue ? (
                  <Typography
                    variant='body2'
                    style={{ fontSize: '0.8rem', fontWeight: 400 }}
                    className={classes.pilotAmount}
                  >
                    {`~ ${formatDollarAmount(parseFloat(burnDollarValue))}`}
                  </Typography>
                ) : (
                  <Typography
                    variant='body2'
                    style={{ fontSize: '0.8rem', fontWeight: 400 }}
                    className={classes.pilotAmount}
                  />
                )
              }
            />

            <Box mt={'30px'} />
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <Typography
                  style={{
                    fontSize: '1rem',
                    fontWeight: 300,
                  }}
                >
                  You will get
                </Typography>
              </Grid>

              <Grid item>
                <Typography
                  style={{
                    fontSize: '2.5rem',
                    fontWeight: 500,
                    letterSpacing: '2px',
                  }}
                  color='primary'
                >
                  {returnAmount ? '~' + formatDollarAmount(parseFloat(returnAmount)) : '-'}
                </Typography>
              </Grid>
              <Grid item style={{ height: '10px' }}>
                {returnPercentage && (
                  <Typography
                    style={{
                      fontSize: '1rem',
                      fontWeight: 300,
                    }}
                  >
                    <strong>{trunc(returnPercentage)}%</strong> of value extracted
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DisableElements>

          <Box mt={'50px'} />

          <ButtonIndicator
            disabled={dappLoading === LOADING.APPROVE || dappLoading === LOADING.BURN}
            onlyDisable={burnDisable}
            onClick={burnAction}
            disableElevation
            className={classes.buttonBottom}
            variant='contained'
            label={burnLabel}
            fullWidth
            color='primary'
            style={{
              textTransform: 'none',
              minHeight: 45,
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default Add;
