import { useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import Jazzicon from '@metamask/jazzicon';

const Identicon = () => {
  const { account } = useWeb3React();

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (account && ref.current) {
      ref.current.innerHTML = '';
      ref.current.appendChild(Jazzicon(20, parseInt(account.slice(2, 10), 16)));
    }
  }, [account]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ref={ref as any}
    />
  );
};

export default Identicon;
