import { useCallback } from 'react';

import { NonPayableTransactionObject } from '../types/types';
import { logError } from '../../utils/logs';

export const useSingleCall = <T, D>(
  method: (() => NonPayableTransactionObject<T>) | undefined,
  message: string,
  defaultValue: D
) => {
  return useCallback(async () => {
    if (method) {
      try {
        const res = await method().call();
        return res;
      } catch (e) {
        logError(message, e);
        return defaultValue;
      }
    } else return defaultValue;
  }, [method, message, defaultValue]);
};

export const useSingleCallWithData = <T, D, M>(
  method: ((...params: M[]) => NonPayableTransactionObject<T>) | undefined,
  message: string,
  defaultValue: D
) => {
  return useCallback(
    async (params: M[]) => {
      if (method) {
        try {
          const res = await method(...params).call();
          return res;
        } catch (e) {
          logError(message, e);
          return defaultValue;
        }
      } else return defaultValue;
    },
    [method, message, defaultValue]
  );
};
