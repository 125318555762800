import { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { MaxUint256 } from '@ethersproject/constants';

import { useERC20Contract } from './contractHooks';
import { useSingleCall, useSingleCallWithData } from './callHooks';
import { useSendTransaction } from './sendHooks';
import { CONTRACT_ADDRESSES } from '../../constants';
import { IToken } from '../../utils/generalTypes';
import { LOADING } from '../../utils/enums';

export const useERC20Token = (tokenAddress?: string) => {
  const contract = useERC20Contract(tokenAddress);
  const getName = useSingleCall(contract?.methods.name, 'getName', '');
  const getSymbol = useSingleCall(contract?.methods.symbol, 'getSymbol', 'UNKNOWN');
  const getDecimals = useSingleCall(contract?.methods.decimals, 'getDecimals', undefined);
  const getBalance = useSingleCallWithData(contract?.methods.balanceOf, 'getBalance', '');
  const getTotalSupply = useSingleCall(contract?.methods.totalSupply, 'getgetTotalSupply', '0');
  const getAllowance = useSingleCallWithData(contract?.methods.allowance, 'getAllowance', '0');
  const approve = useSendTransaction(contract?.methods.approve, 'approve');

  return { getName, getSymbol, getDecimals, getBalance, getTotalSupply, getAllowance, approve };
};

export const useTokenApproval = (token?: IToken) => {
  const { account, chainId } = useWeb3React();
  const { getAllowance, approve } = useERC20Token(token?.address);
  const [approved, setApproved] = useState<boolean | undefined>();

  const checkTokenApproval = useCallback(async () => {
    if (account && token?.address) {
      const allowance = await getAllowance([account, CONTRACT_ADDRESSES.indexFund]);
      setApproved(Number(allowance) > 0);
    }
  }, [account, getAllowance]);

  const approveToken = useCallback(async () => {
    const args = {
      txnMessage: `Approve ${token?.symbol}`,
      dappLoading: LOADING.APPROVE,
      callback: () => setApproved(true),
    };
    await approve([CONTRACT_ADDRESSES.indexFund, MaxUint256._hex], args);
  }, [approve]);

  useEffect(() => {
    checkTokenApproval();
  }, [account, chainId, token]);

  return { approved, approveToken, checkTokenApproval };
};
