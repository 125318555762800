import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { indexFundClient as client } from '../apollo/client';
import { INDEX_FUND_TOKENS_DAY_DATA, TVL_DAY_DATA } from '../apollo/queries';
import { logError } from '../utils/logs';
import { TVL_START_DATE } from '../constants';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
const ONE_DAY_UNIX = 24 * 60 * 60;

interface IndexFundTokenDayData {
  tokenChartDays: {
    id: string;
    currentIndexAmount: string;
    timestamp: string;
  }[];
}

interface IndexFundTVLDayData {
  tvldays: {
    id: string;
    currentTVL: string;
    timestamp: string;
  }[];
}

export type TokenChartData = {
  date: number;
  balance: number;
};

export const fetchIndexFundTokenDayData = async (tokenAddress: string) => {
  const startTime = 1632482500;
  const endTimestamp = dayjs.utc().unix();
  try {
    const { data, error, loading } = await client.query<IndexFundTokenDayData>({
      query: INDEX_FUND_TOKENS_DAY_DATA,
      variables: {
        address: tokenAddress,
        startTime,
      },
      fetchPolicy: 'cache-first',
    });

    if (!loading && data && data.tokenChartDays.length > 0) {
      const formattedExisting = data.tokenChartDays.reduce(
        (accum: { [date: number]: TokenChartData }, dayData) => {
          const roundedDate = parseInt((Number(dayData.timestamp) / ONE_DAY_UNIX).toFixed(0));
          accum[roundedDate] = {
            date: Number(dayData.timestamp),
            balance: parseFloat(dayData.currentIndexAmount),
          };
          return accum;
        },
        {}
      );

      const firstEntry = formattedExisting[0];
      let timestamp = firstEntry?.date ?? startTime;
      let latestBalance = firstEntry?.balance ?? 0;

      while (timestamp < endTimestamp - ONE_DAY_UNIX) {
        const nextDay = timestamp + ONE_DAY_UNIX;
        const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
        if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
          formattedExisting[currentDayIndex] = {
            date: nextDay,
            balance: latestBalance,
          };
        } else {
          latestBalance = formattedExisting[currentDayIndex].balance;
        }
        timestamp = nextDay;
      }

      const dateMap = Object.keys(formattedExisting).map(key => {
        const value = formattedExisting[parseInt(key)];
        return [value.date * 1000, value.balance];
      });

      return dateMap;
    } else {
      logError('fetchIndexFundTokenDayData-e', error);
      return [];
    }
  } catch (e) {
    logError('fetchIndexFundTokenDayData', e);
  }
};

export type TvlChartData = {
  date: number;
  tvl: number;
};

export const fetchIndexFundTvlDayData = async () => {
  const startTime = TVL_START_DATE;
  const endTimestamp = dayjs.utc().unix();

  let data: {
    id: string;
    currentTVL: string;
    timestamp: string;
  }[] = [];

  let error = false;
  let skip = 0;
  let allFound = false;

  try {
    while (!allFound) {
      const {
        data: resData,
        error,
        loading,
      } = await client.query<IndexFundTVLDayData>({
        query: TVL_DAY_DATA,
        variables: {
          startTime,
          skip,
        },
        fetchPolicy: 'cache-first',
      });

      if (!loading) {
        skip += 1000;
        if (resData.tvldays.length < 1000 || error) {
          allFound = true;
        }
        if (resData) {
          data = data.concat(resData.tvldays);
        }
      }
    }

    if (data && data.length > 0) {
      const formattedExisting = data.reduce((accum: { [date: number]: TvlChartData }, dayData) => {
        const roundedDate = parseInt((Number(dayData.timestamp) / ONE_DAY_UNIX).toFixed(0));
        accum[roundedDate] = {
          date: Number(dayData.timestamp),
          tvl: parseFloat(dayData.currentTVL),
        };
        return accum;
      }, {});

      const firstEntry = formattedExisting[0];
      let timestamp = firstEntry?.date ?? startTime;
      let latestTvl = firstEntry?.tvl ?? 0;

      while (timestamp < endTimestamp - ONE_DAY_UNIX) {
        const nextDay = timestamp + ONE_DAY_UNIX;
        const currentDayIndex = parseInt((nextDay / ONE_DAY_UNIX).toFixed(0));
        if (!Object.keys(formattedExisting).includes(currentDayIndex.toString())) {
          formattedExisting[currentDayIndex] = {
            date: nextDay,
            tvl: latestTvl,
          };
        } else {
          latestTvl = formattedExisting[currentDayIndex].tvl;
        }
        timestamp = nextDay;
      }

      const dateMap = Object.keys(formattedExisting).map(key => {
        const value = formattedExisting[parseInt(key)];
        return [value.date * 1000, value.tvl];
      });

      return dateMap;
    } else {
      logError('fetchIndexFundTvlDayData-e', error);
      return [];
    }
  } catch (e) {
    logError('fetchIndexFundTvlDayData', e);
  }
};
