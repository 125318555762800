import { useCallback, useEffect, useState } from 'react';

import { fetchIndexFundTvlDayData } from '../data';

export const useIndexFundTVL = () => {
  const [indexFundTvl, setIndexFundTvl] = useState<number[][] | undefined>();

  const getIndexFundTvl = useCallback(async () => {
    const res = await fetchIndexFundTvlDayData();
    if (res) setIndexFundTvl(res);
    else setIndexFundTvl([]);
  }, [fetchIndexFundTvlDayData, setIndexFundTvl]);

  useEffect(() => {
    getIndexFundTvl();
  }, [getIndexFundTvl]);

  return indexFundTvl;
};
