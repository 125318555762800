import { useState, useEffect, useCallback } from 'react';

import { AppState } from '../';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useEth } from '../../hooks';
import { useERC20Token } from '../../contracts/hooks/erc20Hooks';
import { useIndexFund } from '../../contracts/hooks/indexFundHooks';
import {
  setPilotBalance,
  setPilotBurnAmount,
  selectIndexFundToken,
  unselectIndexFundToken,
  clearSelectedIndexFundTokens,
  getIndexFundOverallData,
  setGraphToken,
  autoSelectTokens,
  setAutoSelect,
  setConnectModal,
  setTokenSelectModal,
  setConfirmationModal,
  setShouldUpdateAutoSelect,
} from './actions';
import { AMOUNT_INPUT_REGEX, TOKEN_AMOUNT_THRESHOLD } from '../../constants';
import { IToken } from '../../utils/generalTypes';
import { formatAmount } from '../../utils/formating';
import { fetchIndexFundTokenDayData } from '../../data';

export const usePilotToken = () => {
  const pilotToken = useAppSelector((state: AppState) => state.burn.pilotToken);
  const dispatch = useAppDispatch();
  const { getBalance } = useERC20Token(pilotToken.address);

  const updatePilotBalance = useCallback(
    async (account: string) => {
      const balance = await getBalance([account]);
      if (balance !== '') {
        dispatch(setPilotBalance(balance));
      }
    },
    [dispatch, getBalance]
  );

  return { pilotToken, updatePilotBalance };
};

export const usePilotBurnAmount = () => {
  const pilotBurnAmount = useAppSelector((state: AppState) => state.burn.pilotBurnAmount);
  const dispatch = useAppDispatch();

  const updatePilotBurnAmount = useCallback(
    (amount: string) => {
      if (amount === '.') dispatch(setPilotBurnAmount('0.'));
      else if (amount === '' || AMOUNT_INPUT_REGEX.test(amount))
        dispatch(setPilotBurnAmount(amount));
    },
    [dispatch, setPilotBurnAmount]
  );

  return { pilotBurnAmount, updatePilotBurnAmount };
};

export const useIndexFundTokens = () => {
  const indexFundTokens = useAppSelector((state: AppState) =>
    state.burn.allIndexFundTokens?.filter(({ balance }) => parseFloat(balance ?? '0') > 0)
  );
  const selectedIndexFundTokens = useAppSelector(
    (state: AppState) => state.burn.selectedIndexFundTokens
  );
  const dispatch = useAppDispatch();

  const selectIFToken = useCallback(
    (token: IToken) => {
      dispatch(selectIndexFundToken(token));
    },
    [dispatch, selectIndexFundToken]
  );

  const unSelectIFToken = useCallback(
    (tokenAddress: string) => {
      dispatch(unselectIndexFundToken(tokenAddress));
    },
    [dispatch, unselectIndexFundToken]
  );

  const clearSelectedIFTokens = useCallback(() => {
    dispatch(clearSelectedIndexFundTokens());
  }, [dispatch, clearSelectedIndexFundTokens]);

  return {
    indexFundTokens,
    selectedIndexFundTokens,
    selectIFToken,
    unSelectIFToken,
    clearSelectedIFTokens,
  };
};

export const useIndexFundOverallData = () => {
  const { allIndexFundTokens, totalBurnedPilotTokens, circulatingSupply, indexFundDollarValue } =
    useAppSelector((state: AppState) => state.burn);
  const dispatch = useAppDispatch();
  const { getETH } = useEth();
  const { getCirculatingSupply } = useIndexFund();

  const getIFOverallData = useCallback(async () => {
    const circulatingSupply = formatAmount(await getCirculatingSupply(), 18);
    const eth = await getETH();
    dispatch(getIndexFundOverallData({ circulatingSupply, eth }));
  }, [dispatch, getIndexFundOverallData]);

  return {
    allIndexFundTokens,
    totalBurnedPilotTokens,
    circulatingSupply,
    getIFOverallData,
    indexFundDollarValue,
  };
};

export const useGraphTokenSelection = () => {
  const graphToken = useAppSelector((state: AppState) => state.burn.graphToken);
  const indexFundGraphTokens = useAppSelector((state: AppState) =>
    state.burn.allIndexFundTokens?.filter(
      ({ balance, usdPrice }) =>
        parseFloat(balance ?? '0') * parseFloat(usdPrice ?? '0') > TOKEN_AMOUNT_THRESHOLD
    )
  );
  const dispatch = useAppDispatch();
  const [tokenChartData, setTokenChartData] = useState<number[][] | undefined>();

  const selectToken = useCallback(
    (token: IToken) => {
      dispatch(setGraphToken(token));
    },
    [dispatch, setGraphToken]
  );

  const getTokensChartData = useCallback(async () => {
    setTokenChartData(await fetchIndexFundTokenDayData(graphToken.address));
  }, [graphToken.address]);

  useEffect(() => {
    getTokensChartData();
  }, [graphToken.address]);

  return { graphToken, indexFundGraphTokens, tokenChartData, selectToken, getTokensChartData };
};

export const useAutoSelectAction = () => {
  const autoSelect = useAppSelector((state: AppState) => state.burn.autoSelect);
  const autoSelectedTokens = useAppSelector((state: AppState) => state.burn.autoSelectedTokens);
  const shouldUpdateAutoSelect = useAppSelector(
    (state: AppState) => state.burn.shouldUpdateAutoSelect
  );
  const dispatch = useAppDispatch();

  const handleSetAutoSelect = useCallback(
    (value: boolean) => {
      dispatch(setAutoSelect(value));
    },
    [dispatch, setAutoSelect]
  );

  const autoSelectingTokens = useCallback(
    (tokens: IToken[]) => {
      dispatch(autoSelectTokens(tokens));
    },
    [dispatch, autoSelectTokens]
  );

  const handleShouldUpdateAutoSelect = useCallback(
    (value: boolean) => {
      dispatch(setShouldUpdateAutoSelect(value));
    },
    [dispatch, setShouldUpdateAutoSelect]
  );

  return {
    autoSelect,
    shouldUpdateAutoSelect,
    autoSelectedTokens,
    handleSetAutoSelect,
    autoSelectingTokens,
    handleShouldUpdateAutoSelect,
  };
};

export const useBurnModals = () => {
  const modals = useAppSelector((state: AppState) => state.burn.modals);
  const dispatch = useAppDispatch();

  const toggleConnectModal = useCallback(
    (value: boolean) => {
      dispatch(setConnectModal(value));
    },
    [dispatch, setConnectModal]
  );

  const toggleTokenSelectModal = useCallback(
    (value: boolean) => {
      dispatch(setTokenSelectModal(value));
    },
    [dispatch, setTokenSelectModal]
  );

  const toggleConfirmationModal = useCallback(
    (value: boolean) => {
      dispatch(setConfirmationModal(value));
    },
    [dispatch, setConfirmationModal]
  );

  return { ...modals, toggleConnectModal, toggleTokenSelectModal, toggleConfirmationModal };
};
