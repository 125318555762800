import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { Web3ReactProvider } from '@web3-react/core';
import { Provider as ReduxProvider } from 'react-redux';

import { themeDark } from '../theme/materialUiTheme';
import Routes from './Routes';
import { getLibrary } from '../utils/walletConnectFunctions';
import Updater from './Updater';
import { store } from '../state';

function App() {
  return (
    <ReduxProvider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeProvider theme={themeDark}>
          <CssBaseline />
          <Updater />
          <Routes />
        </ThemeProvider>
      </Web3ReactProvider>
    </ReduxProvider>
  );
}

export default App;
