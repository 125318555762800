import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  dialogPaperTrans: {
    maxWidth: '410px !important',
    width: '100vw',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  paperWidthSm: {
    maxWidth: '400px !important',
  },
  dialogPaper: {
    maxWidth: '410px !important',
    width: '100vw',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    overflow: 'hidden',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 1rem !important',
    marginTop: 5,
  },
  listItem02: {
    border: `1px solid rgba(255,255,255,0.15)`,

    '&:hover': {
      color: theme.palette.common.white,
      cursor: 'pointer',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    backgroundColor: `${theme.palette.secondary.main} !important`,
    marginTop: 10,
    padding: '10px 15px',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
  },
  selected: {
    backgroundColor: theme.palette.common.hoverBC01,
  },
  listItemAvatar: {
    minWidth: '40px !important',
  },
  input: {
    backgroundColor: `${theme.palette.secondary.main} !important`, //"#fafafa",
    border: '1px solid rgba(255,255,255,0.15)',
    padding: '5px 15px',
    borderRadius: theme.shape.borderRadius,
    marginTop: 15,
    fontSize: '0.8rem !important',
  },
  container: {
    padding: 0,
  },
  box: {
    padding: '0px 20px',
    margin: '20px 0px',
  },

  sec: {
    fontSize: '0.7rem',
    color: '#B9B9B9',
  },
  pri: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  sec02: {
    fontSize: '0.7rem',
    color: '#B9B9B9',

    textAlign: 'right',
  },
  pri02: {
    fontSize: '0.9rem',
    fontWeight: 500,
    textAlign: 'right',
  },
  num: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  avatarRoot: {
    width: 25,
    height: 25,
    backgroundColor: 'transparent',
  },
  MUIContainer: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(255,255,255,0.05) !important',
  },
  marginTop: {
    marginTop: 15,
  },
  WContainer: {
    border: `1px solid rgba(255,255,255,0.2)`,
    padding: '15px 20px',
    borderRadius: theme.shape.borderRadius,
    marginTop: 10,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    textTransform: 'none',
    '&:before': {
      borderColor: theme.palette.primary.main,
    },
    color: 'white',
  },
  wIcon: {
    width: 25,
    height: 25,
  },
  rootWContainer: {
    paddingBottom: '20px',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  rootContainer: {
    paddingTop: 20,
  },
  bottomSection: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    padding: '10px 0px',
    borderTop: '1px solid rgba(225,255,255,0.15)',
  },
  BSButton: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  createIcon: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
  check: {
    width: 120,
    height: 120,
  },
  listContainer: {
    maxHeight: '20rem',
    overflow: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: theme.shape.borderRadius,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
  },

  listContainer03: {
    maxHeight: '14rem',
    overflow: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: theme.shape.borderRadius,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
  },
  listContainer02: {
    maxHeight: '20rem',
    overflow: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: theme.shape.borderRadius,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
    padding: '0px 10px',
  },
  disableListItem: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    padding: '0px 1rem',
    '&:hover': {
      cursor: 'context-menu',
    },
  },
  selectedManage: {
    color: theme.palette.common.white,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.secondary.main} !important`,
    marginTop: 10,
    padding: '10px 15px',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
  },
  linkTrans: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15rem',
    textTransform: 'uppercase',
    fontSize: '0.8rem',
  },
  confirmIcon: {
    width: 20,
    height: 20,
  },
  ethIcon: {
    margin: '0px 4px',
    width: 20,
    height: 20,
  },
  strFont: {
    fontSize: '1.2rem',
    ['@media (max-width:400px)']: {
      fontSize: '0.9rem',
    },
  },
  buttonContent: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  buttonBottom: {
    textTransform: 'none',
    color: 'white',
    padding: '8px 0px',
  },
  selectedBigBtn: {
    padding: '10px',
    height: '100%',
  },
  unselectedBigBtn: {
    color: '#C9C9C9',
    borderColor: '#C9C9C9',
    padding: '10px',
    height: '100%',
  },
  trx: {
    padding: '5px 0',
    borderRadius: theme.shape.borderRadius,
  },
  trxLink: {
    fontSize: '0.8rem',
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  txnBox: {
    maxHeight: '240px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
  },
  commonTokensContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  secondaryText: {
    color: theme.palette.common.lightText,
    fontSize: '0.8rem',
  },
  acBtn: {
    fontSize: '12px',
    textTransform: 'none',
    padding: '2px',
  },
  userAddressContainer: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: '28px',
      marginLeft: '10px',
      fontWeight: '500',
    },
  },
  accWalletIcon: {
    width: '25px',
    height: '25px',
  },
  accContainer: {
    padding: '0 15px 15px 15px',
    '@media(max-width:400px)': {
      padding: '0 6px 15px 6px',
    },
  },
  addrSec: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width:400px)': {
      marginTop: '5px',
    },
  },
  txMsg: {
    fontSize: '12px',
  },
  Hov: {
    '&:hover': {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: 'pointer',
    },
  },
  dashedLine: {
    marginTop: 5,
    height: '1px',
    opacity: 0.1,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
}));

export default useStyles;
