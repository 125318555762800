import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { TransactionReceipt } from 'web3-core';

import { NonPayableTransactionObject, PayableTransactionObject } from '../types/types';
import { ISendTransactionArgs, ITransaction } from '../../utils/generalTypes';
import { logError } from '../../utils/logs';
import { TRANSACTION_STATUS, SNACKBAR_SEVERITY, MODAL_VARIANT } from '../../utils/enums';
import { useLoading, useSnackbar, useModal } from '../../state/application/hooks';
import { useUserTransactions } from '../../state/transaction/hooks';

export const useTransaction = () => {
  const { showSnackbarF } = useSnackbar();
  const { showModalF } = useModal();
  const { updateLoadingF } = useLoading();
  const { addUserTransaction } = useUserTransactions();

  const transactionPending = useCallback(
    (dappLoading: string, txnHash?: string) => {
      showSnackbarF(SNACKBAR_SEVERITY.INFO, 'Transaction pending', txnHash);
      showModalF(MODAL_VARIANT.LOADING, txnHash);
      updateLoadingF(dappLoading);
    },
    [showSnackbarF, showModalF, updateLoadingF]
  );

  const transactionSuccessful = useCallback(
    (txnHash: string, transaction: ITransaction) => {
      showSnackbarF(SNACKBAR_SEVERITY.SUCCESS, 'Transaction successful', txnHash);
      showModalF(MODAL_VARIANT.SUCCESS, txnHash);
      updateLoadingF('');
      addUserTransaction(transaction);
    },
    [showSnackbarF, showModalF, updateLoadingF, addUserTransaction]
  );

  const transactionRejected = useCallback(() => {
    showSnackbarF(SNACKBAR_SEVERITY.ERROR, 'Transaction rejected', '');
    showModalF(MODAL_VARIANT.BLOCKED, '');
    updateLoadingF('');
  }, [showSnackbarF, showModalF, updateLoadingF]);

  const transactionFailed = useCallback(
    (txnHash?: string, transaction?: ITransaction) => {
      showSnackbarF(SNACKBAR_SEVERITY.ERROR, 'Transaction failed', txnHash);
      showModalF(MODAL_VARIANT.FAILED, txnHash);
      updateLoadingF('');
      transaction && addUserTransaction(transaction);
    },
    [showSnackbarF, showModalF, updateLoadingF, addUserTransaction]
  );

  return { transactionPending, transactionSuccessful, transactionRejected, transactionFailed };
};

type TransactionObject<T> = NonPayableTransactionObject<T> | PayableTransactionObject<T>;

export const useSendTransaction = <T>(
  method: ((...params: any) => TransactionObject<T>) | undefined,
  message: string
) => {
  const { account } = useWeb3React();
  const { transactionPending, transactionSuccessful, transactionRejected, transactionFailed } =
    useTransaction();

  return useCallback(
    async (params: any, args: ISendTransactionArgs) => {
      try {
        if (method && account) {
          let transaction: ITransaction = {
            hash: '',
            account,
            message: '',
            status: TRANSACTION_STATUS.PENDING,
          };
          const { txnMessage, dappLoading, ethValue, callback } = args;
          transactionPending(dappLoading);
          await method(...params)
            .send({ from: account, value: ethValue ?? '0' })
            .on('transactionHash', (txnHash: string) => {
              transaction = {
                hash: txnHash,
                account,
                message: txnMessage,
                status: TRANSACTION_STATUS.PENDING,
              };
              transactionPending(dappLoading, txnHash);
            })
            .then((receipt: TransactionReceipt) => {
              transaction['status'] = TRANSACTION_STATUS.SUCCESS;
              transactionSuccessful(receipt.transactionHash, transaction);
              callback();
            })
            .catch((e: any) => {
              if (e.code === 4001) {
                transactionRejected();
              } else {
                transaction['status'] = TRANSACTION_STATUS.FAILED;
                transactionFailed(transaction.hash, transaction);
              }
              logError(`${message}-e`, e);
            });
        }
      } catch (e) {
        logError(message, e);
        transactionFailed();
      }
    },
    [account, method, message]
  );
};
