import ErrorIcon from '@material-ui/icons/Error';

import { CGrid, CTooltip } from '../';
import { useAutoSelectToolTip } from '../../hooks';

interface ITextWithInfoIcon {
  children: JSX.Element;
  title: string;
}

const TextWithInfoIcon: React.FC<ITextWithInfoIcon> = ({ children, title }) => {
  const { showTooltip, handleTooltipChange } = useAutoSelectToolTip();

  return (
    <>
      <CGrid
        containerProps={{
          spacing: 1,
        }}
        item02Props={{
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        alignItems='center'
        elements01={children}
        elements02={
          <CTooltip open={showTooltip} title={title}>
            <ErrorIcon
              onMouseOut={() => handleTooltipChange(false)}
              onMouseOver={() => handleTooltipChange(true)}
              style={{ width: 17, height: 17 }}
            />
          </CTooltip>
        }
      />
    </>
  );
};

export default TextWithInfoIcon;
