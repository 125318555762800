import axios from 'axios';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchTokensDollarValue } from '../../data';
import { SNACKBAR_SEVERITY, MODAL_VARIANT } from '../../utils/enums';
import { PILOT_ADDRESS } from '../../constants';
import { formatAmount } from '../../utils/formating';

export const showSnackbar = createAction<{
  severity: SNACKBAR_SEVERITY;
  message: string;
  txnHash?: string;
}>('application/showSnackbar');

export const hideSnackbar = createAction<void>('application/hideSnackbar');

export const showModal = createAction<{
  variant: MODAL_VARIANT;
  txnHash?: string;
}>('application/showModal');

export const hideModal = createAction<void>('application/hideModal');

export const updateLoading = createAction<string>('application/updateLoading');

export const setPilotPirce = createAsyncThunk('application/setPilotPirce', async () => {
  const res = await fetchTokensDollarValue([PILOT_ADDRESS]);
  if (res) {
    return res[PILOT_ADDRESS].usd.toString();
  }
});

export const currentGasPrice = createAsyncThunk('application/currentGasPrice', async () => {
  const WETH_ADDRESS = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
  const gasRes = await axios.get('https://ethgasstation.info/json/ethgasAPI.json');
  const ethPriceRes = await fetchTokensDollarValue([WETH_ADDRESS]);
  if (gasRes && ethPriceRes) {
    const etherPrice = ethPriceRes.hasOwnProperty(WETH_ADDRESS) ? ethPriceRes[WETH_ADDRESS].usd : 0;

    const transferGas = (gasRes.data.average / 10) * 21000;
    const gasPriceInETH = formatAmount((transferGas * 1000000000).toString(), 18);
    //console.log('Transfer Gas => ', (parseFloat(gasPriceInETH) * etherPrice).toString());
    return (parseFloat(gasPriceInETH) * etherPrice).toString();
  }
});
