import { useCallback } from 'react';

import { CONTRACT_ADDRESSES, ETH } from '../constants';
import { useProvider } from './';
import { formatAmount } from '../utils/formating';

export const useEth = () => {
  const provider = useProvider();

  const getEthBalance = useCallback(async () => {
    const ethBalance = await provider?.eth.getBalance(CONTRACT_ADDRESSES.indexFund);
    return ethBalance ?? '0';
  }, [provider]);

  const getETH = useCallback(async () => {
    const ethBalance = await getEthBalance();
    return { ...ETH, balance: formatAmount(ethBalance, ETH.decimals) };
  }, [getEthBalance]);

  return { getEthBalance, getETH };
};
