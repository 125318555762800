import { useMemo } from 'react';
import {
  Typography,
  Box,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { BigNumber } from 'bignumber.js';

import useStyles from '../styles';
import { TokenLogo, CTooltip } from '../../';
import { useIndexFundTokenSelection, useUserShare } from '../../../hooks';
import { IToken } from '../../../utils/generalTypes';
import { formatDollarAmount, trunc, exponentialToDecimal } from '../../../utils/formating';

export interface IListItem {
  token: IToken;
  tokenSelect?: ((token: IToken) => void) | undefined;
  withUserShare?: boolean;
  showDollarValue?: boolean;
}

export const ListItem: React.FC<IListItem> = ({
  token,
  withUserShare = false,
  showDollarValue = false,
}) => {
  const classes = useStyles();
  const { calculateUserShareValue } = useUserShare();

  const tokenAmount = useMemo<string>(() => {
    return token.balance
      ? withUserShare
        ? calculateUserShareValue(token.balance)
        : token.balance
      : '0';
  }, [withUserShare, token.balance, calculateUserShareValue]);

  const tokenDV = useMemo<string>(() => {
    return showDollarValue && token.usdPrice
      ? new BigNumber(token.usdPrice).multipliedBy(tokenAmount).toString()
      : '0';
  }, [showDollarValue, token.usdPrice, tokenAmount]);

  return (
    <MuiListItem className={classes.listItem}>
      <ListItemAvatar
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          margin: '0px 5px',
        }}
        className={classes.listItemAvatar}
      >
        <TokenLogo uri={token.logoURI} />
      </ListItemAvatar>
      <ListItemText
        classes={{
          secondary: classes.sec,
          primary: classes.pri,
        }}
        primary={token.symbol}
        secondary={token.name}
      />
      <Box>
        {parseFloat(tokenAmount) > 0 ? (
          <CTooltip title={exponentialToDecimal(tokenAmount)}>
            <Typography className={classes.pri02} variant='body2'>
              {trunc(tokenAmount)}
            </Typography>
          </CTooltip>
        ) : (
          <Typography className={classes.pri02} variant='body2'>
            0
          </Typography>
        )}
        {showDollarValue &&
          (token.usdPrice ? (
            <Typography className={classes.sec02} variant='body2'>
              {formatDollarAmount(parseFloat(tokenDV))}
            </Typography>
          ) : (
            <Typography className={classes.sec02} variant='body2'>
              -
            </Typography>
          ))}
      </Box>
    </MuiListItem>
  );
};

export const CheckListItem: React.FC<IListItem> = ({ token }) => {
  const classes = useStyles();
  const { isTokenSelected, handleTokenSelect } = useIndexFundTokenSelection();

  return (
    <Box
      display='flex'
      alignItems='center'
      className={classes.Hov}
      onClick={() => handleTokenSelect(token)}
    >
      <Checkbox
        color='primary'
        style={{ marginLeft: '10px' }}
        checked={isTokenSelected(token.address)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      <ListItem token={token} withUserShare />
    </Box>
  );
};

export const TokenListItem: React.FC<IListItem> = ({ token, tokenSelect }) => {
  const classes = useStyles();

  return (
    <Box
      onClick={tokenSelect ? () => tokenSelect(token) : () => {}}
      display='flex'
      alignItems='center'
      className={classes.Hov}
    >
      <ListItem token={token} />
    </Box>
  );
};
