import { Grid, Typography, CircularProgress, Box } from '@material-ui/core';

import useStyles from './styles';

interface GBox {
  text: string;
  value: string;
  loading?: boolean;
}

const GBox: React.FC<GBox> = ({ text, value, loading = false }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.box} direction='column' alignItems='center'>
      <Grid item>
        <Typography style={{ fontSize: '0.8rem' }} color='primary'>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        {loading ? (
          <Box height='50px' display='flex' alignItems='center' justifyContent='center'>
            <CircularProgress size={15} />
          </Box>
        ) : (
          <Typography
            variant='body2'
            style={{ fontSize: '1.8rem', fontWeight: 500, marginTop: 5 }}
            className={classes.gBoxValue}
          >
            {value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default GBox;
