import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  text: {
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
  },
  iconLoud: {
    width: 19.44,
    height: 20,
    marginRight: `${theme.spacing(2)}px`,
  },
}));

export default useStyles;
