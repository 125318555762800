import loud from '../../assets/others/loud.png';
import wallet from '../../assets/others/wallet.png';
import metaMask from '../../assets/wallets/metamask.png';
import coinbase from '../../assets/wallets/coinbase.png';
import fortmatic from '../../assets/wallets/fortmatic.png';
import injected from '../../assets/wallets/injected.png';
import portis from '../../assets/wallets/portis.png';
import walletconnect from '../../assets/wallets/walletconnect.png';
import check from '../../assets/others/check.png';
import remove from '../../assets/others/remove.png';
import block from '../../assets/others/block.png';
import logo from '../../assets/others/logo.png';
import logoTick from '../../assets/others/logo-tick.svg';
import warning from '../../assets/others/warning.png';

interface Image {
  className?: string;
}

export const Loud: React.FC<Image> = ({ className }) => {
  return <img className={className} src={loud} />;
};

export const Wallet: React.FC<Image> = ({ className }) => {
  return <img className={className} src={wallet} />;
};

export const MetaMask: React.FC<Image> = ({ className }) => {
  return <img className={className} src={metaMask} />;
};

export const CoinBase: React.FC<Image> = ({ className }) => {
  return <img className={className} src={coinbase} />;
};

export const FortMatic: React.FC<Image> = ({ className }) => {
  return <img className={className} src={fortmatic} />;
};

export const Injected: React.FC<Image> = ({ className }) => {
  return <img className={className} src={injected} />;
};

export const Portis: React.FC<Image> = ({ className }) => {
  return <img className={className} src={portis} />;
};

export const WalletConnect: React.FC<Image> = ({ className }) => {
  return <img className={className} src={walletconnect} />;
};

export const Check: React.FC<Image> = ({ className }) => {
  return <img className={className} src={check} />;
};

export const Remove: React.FC<Image> = ({ className }) => {
  return <img className={className} src={remove} />;
};

export const Block: React.FC<Image> = ({ className }) => {
  return <img className={className} src={block} />;
};

export const Logo: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logo} />;
};

export const LogoTick: React.FC<Image> = ({ className }) => {
  return <img className={className} src={logoTick} />;
};

export const Warning: React.FC<Image> = ({ className }) => {
  return <img className={className} src={warning} />;
};
