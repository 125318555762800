import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: any) => ({
  conatiner: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: 20,
    border: theme.palette.common.border,
    padding: '1.5rem',
  },
  tokenPair: {
    margin: '15px 0px',
  },
  marginTop: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  paddingRight5: {
    paddingRight: 6,
  },
  paddingLeft5: {
    paddingLeft: 6,
  },
  buttonBottom: {
    textTransform: 'none',
    color: '#fff',
    padding: '10px 0px',
    minHeight: 45,
  },
  textMarginTop: {
    marginTop: 5,
  },
  bar: {
    '&:hover': {
      color: 'red',
    },
  },
  topLeftBtn: {
    textTransform: 'none',
    padding: '8px 0px',
  },
  marginTopMost: {
    marginTop: 100,
  },
  bottomSelect: {
    borderRadius: 0,
  },
  bottomInput: {
    borderRadius: 0,
  },
  inputMaxContainer: {
    fontWeight: 500,
    padding: '4px 20px',
    borderRadius: 0,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    minHeight: 50,
    paddingBottom: '13px',
  },
  inputStartingPrice: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    padding: '4px 20px',
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: 5,
    minHeight: 50,
  },
  bottomHeight: {
    height: theme.mixins.toolbar.minHeight,
  },
  dropButton: {
    textTransform: 'none',
    justifyContent: 'space-between',
    padding: '10px 20px',
    borderColor: 'transparent',
    minHeight: 50,
  },
  borderPrimary: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  secondaryBC: {
    backgroundColor: theme.palette.secondary.main,
  },

  graphContainer: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    border: theme.palette.common.border,
    borderRadius: 20,
    height: '100%',
  },
  notification: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: theme.palette.primary.main,
    border: '1px solid rgba(255, 255, 255, 0.3)',
    padding: '15px 10px',
    textAlign: 'center',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 55,
  },
  pilotTypo: {
    textAlign: 'center',
  },
  buttonContainer: {
    paddingRight: 15,
  },
  buttonContent: {
    fontSize: '0.8rem',
    fontWeight: 500,
  },
  ethIcon: {
    margin: '0px 4px',
    width: 20,
    height: 20,
  },
  buttonContainerDropDown: {
    backgroundColor: 'rgba(0,0,0,0.6) !important',
    border: '1px solid rgba(255,255,255,0.3) !important',
    padding: '5px 15px',
    borderRadius: 8,
    textTransform: 'none',
  },
  box: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '15px',
    borderRadius: 15,
    border: '1px solid rgba(255, 255, 255, 0.1)',
    textTransform: 'none',
  },
  boxContainer: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    border: theme.palette.common.border,
    padding: '0px 10px',
    borderRadius: 15,
  },
  boxInnerContainer: {
    padding: '15px 10px',
  },
  inputContainer: {
    background: 'linear-gradient(184.82deg, #03101F -27.33%, #082445 100.97%)',
    borderRadius: 10,
    border: '1px solid rgba(255, 255, 255, 0.05)',
  },
  imgContainer: {
    marginLeft: 10,
    width: 40,
    height: 25,
  },
  rootPilot: {
    display: 'flex',
    cursor: 'pointer',
    background: 'rgba(0, 0, 0, 0.32)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    height: 45,
    marginTop: 3,
    marginLeft: 2,
    borderRadius: 10,
    textAlign: 'center',
    padding: '9px 0px',
  },
  textContainer: {
    marginLeft: 5,
    margin: '3px 5px',
  },
  pilotAmount: {
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    fontWeight: 400,
    letterSpacing: '0.7px',
    color: 'rgba(255,255,255,0.6)',
    height: 20,
  },
  gBoxValue: {
    fontSize: '1.8rem',
    fontWeight: 500,
    marginTop: 5,
  },
  smContainer: {
    padding: '5px 20px',
    textTransform: 'none',
    fontSize: '0.8rem',
    marginLeft: 5,
    minWidth: '10px',
    borderRadius: '20px',
  },
  selectTokenInputMaxContainer: {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    borderRadius: theme.shape.borderRadius * 1.5,
  },
  typoPri: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: '0px 8px',
  },
  pilotBtnContainer: {
    color: 'white',
    cursor: 'default',
    textTransform: 'none',
    justifyContent: 'flex-start',
    borderRadius: theme.shape.borderRadius,
    minHeight: 50,
    background: 'rgba(0,0,0,0.3)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.1)',
      background: 'rgba(0,0,0,0.3)',
    },
  },
  DDDContainer: {
    padding: 0,
  },
  DDDBox: {
    padding: '0px 20px',
    margin: '20px 0px',
  },
  DDDInput: {
    backgroundColor: `${theme.palette.secondary.main} !important`, //"#fafafa",
    border: '1px solid rgba(255,255,255,0.15)',
    padding: '5px 15px',
    borderRadius: theme.shape.borderRadius,
    marginTop: 15,
    fontSize: '0.8rem !important',
  },
  DDDListContainer: {
    maxHeight: '20rem',
    overflow: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: theme.shape.borderRadius,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
    },
  },
  DDDHov: {
    '&:hover': {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: 'pointer',
    },
  },
  DDDListItem: {
    padding: '0px 1rem !important',
    '&:hover': {
      backgroundColor: theme.palette.common.hoverBC01,
      cursor: 'pointer',
    },
    marginTop: 5,
  },
  DDDListItemAvatar: {
    minWidth: '40px !important',
  },
  DDDSec: {
    fontSize: '0.7rem',
    color: '#B9B9B9',
  },
  DDDPri: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
  timeBox: {
    height: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    '& p': {
      color: theme.palette.common.lightText,
      fontSize: '12px',
    },
  },
  rac: {},
}));

export default useStyles;
