import { useEffect } from 'react';

export const useInterval = (callBack: any, duration: number, dependancyArray: any[]) => {
  useEffect(() => {
    callBack();
  }, [...dependancyArray]);

  useEffect(() => {
    const interval = setInterval(() => {
      callBack();
    }, duration);

    return () => clearInterval(interval);
  }, [...dependancyArray]);
};
