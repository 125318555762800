import { useState, useEffect } from 'react';

import useStyles from './styles';
import { UN_KNOWN_LOGO } from '../../constants';
import { getTokenLogo } from '../../data';

interface ITokenLogo {
  uri: string | undefined;
  className?: string;
}

const TokenLogo: React.FC<ITokenLogo> = ({ uri, className }) => {
  const classes = useStyles();
  const [logo, setLogo] = useState<string>(UN_KNOWN_LOGO);

  const fetchLogo = async (): Promise<void> => {
    if (uri) {
      const res = await getTokenLogo(uri);
      setLogo(res);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, [uri]);

  return <img className={`${classes.logo} ${className ? className : ''}`} src={logo} alt='icon' />;
};

export default TokenLogo;
