import { useCallback } from 'react';

import { useIndexFundContract } from './contractHooks';
import { useSendTransaction } from './sendHooks';
import { useSingleCall } from './callHooks';
import { LOADING } from '../../utils/enums';

export const useIndexFund = () => {
  const contract = useIndexFundContract();
  const getCirculatingSupply = useSingleCall(
    contract?.methods.circulatingSupply,
    'getCirculatingSupply',
    ''
  );
  const withdraw = useSendTransaction(contract?.methods.withdraw, 'withdraw');

  return { getCirculatingSupply, withdraw };
};

export const useWithraw = () => {
  const { withdraw } = useIndexFund();

  const withdrawTokens = useCallback(
    async (
      tokenAddress: string[],
      pilotAmount: string,
      burnDollarValue: string,
      callback?: any
    ) => {
      const args = {
        txnMessage: `Burned ~ $${burnDollarValue} Pilot`,
        dappLoading: LOADING.BURN,
        callback,
      };
      await withdraw([tokenAddress, pilotAmount], args);
    },
    [withdraw]
  );

  return { withdrawTokens };
};
