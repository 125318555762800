import Countdown from 'react-countdown';

import Layout from '../config/Layout';
import IndexFund from './indexFund';
import CountDown from './countDown';

const renderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <Layout>
        <IndexFund />
      </Layout>
    );
  } else return <CountDown days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};

//1634239800000

export const IndexFundWithCountDown = () => {
  return <Countdown date={1634239800000} renderer={renderer} />;
};
