import Web3 from 'web3';

import { IToken } from './generalTypes';
import { parseAmount } from './formating';

export const getNetworkName = (chainId: number | undefined): string => {
  if (chainId === 1) return 'Mainnet';
  else if (chainId === 3) return 'Ropsten';
  else if (chainId === 4) return 'Rinkeby';
  else if (chainId === 5) return 'Goerli';
  else if (chainId === 42) return 'Kovan';
  else return 'Wrong Newtwork';
};

export const validAmount = (token: IToken, amount: string): boolean => {
  if (token?.balance) {
    return parseFloat(token?.balance) < parseFloat(parseAmount(amount, token.decimals))
      ? true
      : false;
  } else return false;
};

export const getLogoUri = (tokenAddress: string): string => {
  return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${Web3.utils.toChecksumAddress(
    tokenAddress
  )}/logo.png`;
};
