import { Box } from "@material-ui/core";

interface IDisableElements {
  children: React.ReactNode;
  disabled?: boolean;
}

const DisableElements: React.FC<IDisableElements> = ({
  children,
  disabled,
}) => {
  return (
    <Box
      style={
        disabled
          ? {
              opacity: 0.5,
              pointerEvents: "none",
            }
          : {}
      }
    >
      {children}
    </Box>
  );
};

export default DisableElements;
