import { createReducer } from '@reduxjs/toolkit';

import {
  showSnackbar,
  hideSnackbar,
  showModal,
  hideModal,
  updateLoading,
  setPilotPirce,
  currentGasPrice,
} from './actions';
import { SNACKBAR_SEVERITY, MODAL_VARIANT } from '../../utils/enums';

export interface ApplicationState {
  snackbar: {
    open: boolean;
    severity: SNACKBAR_SEVERITY;
    message: string;
    txnHash?: string;
  };
  modal: {
    open: boolean;
    variant: MODAL_VARIANT;
    txnHash?: string;
  };
  gasPrice: string;
  dappLoading: string;
  pilotPrice: string;
}

const initialState: ApplicationState = {
  snackbar: { open: false, severity: SNACKBAR_SEVERITY.SUCCESS, message: '', txnHash: '' },
  modal: {
    open: false,
    variant: MODAL_VARIANT.LOADING,
    txnHash: '',
  },
  dappLoading: '',
  gasPrice: '',
  pilotPrice: '',
};

export default createReducer(initialState, builder =>
  builder
    .addCase(showSnackbar, (state, action) => {
      state.snackbar = { open: true, ...action.payload };
    })
    .addCase(hideSnackbar, state => {
      state.snackbar = { ...initialState.snackbar };
    })
    .addCase(showModal, (state, action) => {
      state.modal = { open: true, ...action.payload };
    })
    .addCase(hideModal, state => {
      state.modal = { ...initialState.modal };
    })
    .addCase(updateLoading, (state, action) => {
      state.dappLoading = action.payload;
    })
    .addCase(setPilotPirce.fulfilled, (state, action) => {
      state.pilotPrice = action.payload ?? '';
    })
    .addCase(currentGasPrice.fulfilled, (state, action) => {
      state.gasPrice = action.payload ?? '';
    })
);
