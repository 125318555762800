import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import { TransactionDialog, Snackbar, MainHeader } from '../components';

interface ILayout {
  children: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({ children }) => {
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Snackbar />
      <TransactionDialog />
      <Box
        style={{
          height: sm_ ? undefined : '10vh',
        }}
      >
        <MainHeader bottom={sm_} />
      </Box>

      <Box
        style={{
          minHeight: sm_ ? '100vh' : '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px 0px',
          marginBottom: sm_ ? 40 : undefined,
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default Layout;
