import { useCallback } from 'react';

import { AppState } from '../';
import { ITransaction } from '../../utils/generalTypes';
import { useAppDispatch, useAppSelector } from '../hooks';

import { addTransaction, getTransactions, clearTransactions } from './actions';

export const useUserTransactions = () => {
  const transactions = useAppSelector((state: AppState) => state.transaction.transactions);
  const dispatch = useAppDispatch();

  const addUserTransaction = useCallback(
    (txn: ITransaction) => {
      dispatch(addTransaction(txn));
    },
    [dispatch, addTransaction]
  );

  const getUserTransactions = useCallback(
    (account: string) => {
      dispatch(getTransactions(account));
    },
    [dispatch, getTransactions]
  );

  const clearUserTransactions = useCallback(
    (account: string) => {
      dispatch(clearTransactions(account));
    },
    [dispatch, clearTransactions]
  );

  return { transactions, addUserTransaction, getUserTransactions, clearUserTransactions };
};
