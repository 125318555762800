import { useMediaQuery } from '@material-ui/core';

export const useBreakpoints = () => {
  const _xs = useMediaQuery('@media (max-width:500px)');
  const _sm = useMediaQuery('@media (max-width:650px)');
  const _md = useMediaQuery('@media (max-width:1000px)');
  const _lg = useMediaQuery('@media (max-width:1280px)');
  const _xl = useMediaQuery('@media (max-width:1400px)');

  const xs_ = useMediaQuery('@media (min-width:500px)');
  const sm_ = useMediaQuery('@media (min-width:650px)');
  const md_ = useMediaQuery('@media (min-width:1000px)');
  const lg_ = useMediaQuery('@media (min-width:1280px)');
  const xl_ = useMediaQuery('@media (min-width:1400px)');

  return { _xs, _sm, _md, _lg, _xl, xs_, sm_, md_, lg_, xl_ };
};
