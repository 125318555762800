import { Grid, Typography, GridProps, Box } from '@material-ui/core';
import { useHistory } from 'react-router';

import { BackButton } from '../';

interface IHeader {
  disableGoBack?: boolean;
  elements?: React.ReactNode;
  iconRight?: React.ReactNode;
  goBack?: () => void;
  cAlignItems?: GridProps['alignItems'];
  iconRightHidden?: boolean;
  containerStyle?: React.CSSProperties;
}

const Header: React.FC<IHeader> = ({
  elements = <Typography variant='h5'>Header</Typography>,
  disableGoBack = false,
  iconRight,
  goBack,
  cAlignItems,
  iconRightHidden,
  containerStyle,
}) => {
  const history = useHistory();

  return (
    <Grid
      container
      alignItems={cAlignItems ?? 'flex-start'}
      justify='space-between'
      style={containerStyle}
    >
      <Grid item style={{ visibility: disableGoBack ? 'hidden' : 'visible' }}>
        <BackButton
          onClick={
            goBack
              ? goBack
              : () => {
                  history.goBack();
                }
          }
        />
      </Grid>
      <Grid item>{elements}</Grid>
      <Grid item style={{ visibility: iconRightHidden ? 'hidden' : undefined }}>
        {iconRight ? iconRight : <Box style={{ width: '48px' }}></Box>}
      </Grid>
    </Grid>
  );
};

export default Header;
