import { Box, Typography } from '@material-ui/core';

import useStyles from './styles';

import position from '../../assets/svgs/position.svg';

interface INothing {
  children?: React.ReactNode;
  title?: string;
  containerStyle?: React.CSSProperties;
}

const Nothing: React.FC<INothing> = ({
  children,
  title = 'Nothing to show',
  containerStyle = {},
}) => {
  const classes = useStyles();

  const default_ = (
    <Box
      style={{
        padding: '20px',
        opacity: 0.3,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...containerStyle,
      }}
    >
      <img src={position} alt='position image' />
      <Box mt='10px' />
      <Typography variant='h6'>{title}</Typography>
    </Box>
  );

  return (
    <Box style={containerStyle} className={containerStyle ? '' : classes.nothingContainer}>
      {children ?? default_}
    </Box>
  );
};

export default Nothing;
