import { useProvider } from '../../hooks';
import { BaseContract } from '../types/types';
import { isAddress } from '../../utils/formating';
import { CONTRACT_ADDRESSES } from '../../constants';

import Erc20_ABI from '../abis/ERC20.json';
import IndexFund_ABI from '../abis/IndexFund.json';
import { ERC20 } from '../types/ERC20';
import { IndexFund } from '../types/IndexFund';

export const useContract = <T extends BaseContract>(abi: any, address?: string): T | undefined => {
  const provider = useProvider();
  const validAddress = isAddress(address ?? '');
  if (provider && validAddress) {
    return new provider.eth.Contract(abi, validAddress) as any as T;
  }
};

export const useERC20Contract = (tokenAddress?: string): ERC20 | undefined => {
  return useContract<ERC20>(Erc20_ABI, tokenAddress);
};

export const useIndexFundContract = (): IndexFund | undefined => {
  return useContract<IndexFund>(IndexFund_ABI, CONTRACT_ADDRESSES.indexFund);
};
