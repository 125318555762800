import { IconButton, IconButtonProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import useStyles from './styles';

interface IButton {
  classNameContainer: string;
  classNameIcon: string;
}

export const CloseButton: React.FC<IconButtonProps> = ({ onClick = () => {} }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <CloseIcon />
    </IconButton>
  );
};

export const BackButton: React.FC<IconButtonProps> = ({ onClick = () => {} }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.closeButton}>
      <KeyboardBackspaceIcon />
    </IconButton>
  );
};

export const FileCopyButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ''].join(' ')}
    >
      <FileCopyIcon className={classNameIcon} />
    </IconButton>
  );
};

export const TickButton: React.FC<IconButtonProps & IButton> = ({
  onClick = () => {},
  classNameContainer,
  classNameIcon,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={onClick}
      className={[classes.closeButton, classNameContainer ?? ''].join(' ')}
    >
      <CheckCircleIcon className={classNameIcon} />
    </IconButton>
  );
};
