import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';

import { NETWORK_URL } from '../constants';

export const useProvider = () => {
  const { library } = useWeb3React();
  const [provider, setProvider] = useState<Web3>(new Web3(NETWORK_URL));

  useEffect(() => {
    if (window?.web3?.currentProvider) {
      setProvider(new Web3(window?.web3?.currentProvider));
    } else if (library) {
      setProvider(new Web3(library));
    } else {
    }
  }, [library, setProvider, NETWORK_URL]);

  return provider;
};
