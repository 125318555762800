import { Switch } from '@material-ui/core';

import useStyles from './styles';

interface ICSSwitch {
  value: boolean;
  onChange: (value: boolean) => void;
}

const CSwitch: React.FC<ICSSwitch> = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <Switch
      checked={value}
      classes={{
        root: classes.root,
        checked: classes.checked,
        switchBase: classes.switchBase,
        track: classes.track,
        thumb: classes.thumb,
      }}
      onChange={(e, value) => onChange(value)}
    />
  );
};

export default CSwitch;
