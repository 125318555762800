import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  nothingContainer: {
    minHeight: '15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
