import { createReducer } from '@reduxjs/toolkit';

import { addTransaction, getTransactions, clearTransactions } from './actions';
import { ITransaction } from '../../utils/generalTypes';

export interface TransactionState {
  transactions: ITransaction[];
}

let data;
let account: string;
const userTransactions = JSON.parse(localStorage.getItem('transactions') ?? '{}');

const initialState: TransactionState = {
  transactions: [],
};

export default createReducer(initialState, builder =>
  builder
    .addCase(addTransaction, (state, action) => {
      account = action.payload.account;
      data = userTransactions[account] ?? [];
      userTransactions[account] = [action.payload, ...data];
      localStorage.setItem('transactions', JSON.stringify(userTransactions));
      state.transactions = userTransactions[account];
    })
    .addCase(getTransactions, (state, action) => {
      state.transactions = userTransactions[action.payload] ?? [];
    })
    .addCase(clearTransactions, (state, action) => {
      delete userTransactions[action.payload];
      localStorage.setItem('transactions', JSON.stringify(userTransactions));
      state.transactions = [];
    })
);
