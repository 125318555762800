import { createReducer } from '@reduxjs/toolkit';

import {
  setPilotBalance,
  setPilotBurnAmount,
  selectIndexFundToken,
  unselectIndexFundToken,
  clearSelectedIndexFundTokens,
  getIndexFundOverallData,
  setGraphToken,
  autoSelectTokens,
  setAutoSelect,
  setConnectModal,
  setTokenSelectModal,
  setConfirmationModal,
  setShouldUpdateAutoSelect,
} from './actions';
import { IToken } from '../../utils/generalTypes';
import { PILOT } from '../../constants';

export interface BurnState {
  pilotToken: IToken;
  pilotBurnAmount: string;
  selectedIndexFundTokens: IToken[];
  autoSelectedTokens: IToken[];
  allIndexFundTokens: IToken[] | undefined;
  totalBurnedPilotTokens: string | undefined;
  circulatingSupply: string | undefined;
  indexFundDollarValue: string | undefined;
  graphToken: IToken;
  autoSelect: boolean;
  shouldUpdateAutoSelect: boolean;
  modals: {
    connectModal: boolean;
    tokenSelectModal: boolean;
    confirmationModal: boolean;
  };
}

const initialState: BurnState = {
  pilotToken: PILOT,
  pilotBurnAmount: '0',
  selectedIndexFundTokens: [],
  autoSelectedTokens: [],
  allIndexFundTokens: undefined,
  totalBurnedPilotTokens: undefined,
  circulatingSupply: undefined,
  indexFundDollarValue: undefined,
  graphToken: PILOT,
  autoSelect: true,
  shouldUpdateAutoSelect: false,
  modals: {
    connectModal: false,
    tokenSelectModal: false,
    confirmationModal: false,
  },
};

export default createReducer(initialState, builder =>
  builder
    .addCase(setPilotBalance, (state, action) => {
      state.pilotToken.balance = action.payload;
    })
    .addCase(setPilotBurnAmount, (state, action) => {
      state.pilotBurnAmount = action.payload;
    })
    .addCase(selectIndexFundToken, (state, action) => {
      const tokenAlreadySelected = state.selectedIndexFundTokens.filter(
        token => token.address === action.payload.address
      )[0];
      if (!tokenAlreadySelected) {
        state.selectedIndexFundTokens = [...state.selectedIndexFundTokens, action.payload];
      }
      if (state.autoSelect) {
        state.autoSelect = false;
      }
    })
    .addCase(unselectIndexFundToken, (state, action) => {
      state.selectedIndexFundTokens = state.selectedIndexFundTokens.filter(
        ({ address }) => address !== action.payload
      );
      if (state.autoSelect) {
        state.autoSelect = false;
      }
    })
    .addCase(clearSelectedIndexFundTokens, state => {
      state.selectedIndexFundTokens = [];
    })
    .addCase(setGraphToken, (state, action) => {
      state.graphToken = action.payload;
    })
    .addCase(autoSelectTokens, (state, action) => {
      state.autoSelectedTokens = action.payload;
      state.selectedIndexFundTokens = state.autoSelect
        ? action.payload
        : state.selectedIndexFundTokens;
    })
    .addCase(setAutoSelect, (state, action) => {
      state.autoSelect = action.payload;
      state.selectedIndexFundTokens = action.payload ? state.autoSelectedTokens : [];
      state.shouldUpdateAutoSelect = false;
    })
    .addCase(setConnectModal, (state, action) => {
      state.modals.connectModal = action.payload;
    })
    .addCase(setTokenSelectModal, (state, action) => {
      state.modals.tokenSelectModal = action.payload;
    })
    .addCase(setConfirmationModal, (state, action) => {
      state.modals.confirmationModal = action.payload;
    })
    .addCase(setShouldUpdateAutoSelect, (state, action) => {
      state.shouldUpdateAutoSelect = action.payload;
    })
    .addCase(getIndexFundOverallData.fulfilled, (state, action) => {
      state.allIndexFundTokens = action.payload?.allIndexFundTokens;
      state.selectedIndexFundTokens = [];
      state.totalBurnedPilotTokens = action.payload?.totalBurnedPilotTokens;
      state.circulatingSupply = action.payload?.circulatingSupply;
      state.indexFundDollarValue = action.payload?.indexFundDollarValue;
    })
    .addCase(getIndexFundOverallData.rejected, state => {
      state.allIndexFundTokens = [];
      state.selectedIndexFundTokens = [];
      state.totalBurnedPilotTokens = '';
      state.circulatingSupply = '';
      state.indexFundDollarValue = '';
    })
);
