import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { usePilotToken, usePilotBurnAmount, useIndexFundOverallData } from './hooks';
import { useInterval } from '../../hooks';
import { TIME_INTERVAL } from '../../constants';

const Updater = (): null => {
  const { account, chainId } = useWeb3React();
  const { updatePilotBalance } = usePilotToken();
  const { updatePilotBurnAmount } = usePilotBurnAmount();
  const { getIFOverallData } = useIndexFundOverallData();

  useInterval(
    () => {
      if (account) {
        updatePilotBalance(account);
      }
    },
    TIME_INTERVAL,
    [account, chainId]
  );

  useEffect(() => {
    updatePilotBurnAmount('');
    getIFOverallData();
  }, [account]);

  return null;
};

export default Updater;
