import { Snackbar as SnackbarMui, IconButton, Slide, Link, Grid } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { ClearOutlined, LinkOutlined } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { useStyles } from './styles';
import { ETHERSCAN_TX_BASE_URL } from '../../constants';
import { useSnackbar } from '../../state/application/hooks';

const Snack: React.FC<{}> = () => {
  const classes = useStyles();

  const { open, message, severity, txnHash, hideSnackbarF } = useSnackbar();

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction='up' />;
  }

  return (
    <SnackbarMui
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={60000}
      onClose={hideSnackbarF}
      TransitionComponent={TransitionUp}
      action={
        <IconButton size='small' aria-label='close' color='inherit' onClick={hideSnackbarF}>
          <ClearOutlined fontSize='small' />
        </IconButton>
      }
    >
      <Alert
        onClose={hideSnackbarF}
        severity={severity}
        className={classes.snackbarStyles}
        style={open ? {} : { display: 'none' }}
      >
        <Grid container direction='column' alignItems='center'>
          <Grid item>{message}</Grid>
          {txnHash && (
            <Grid item>
              <Link
                component='a'
                className={classes.etherscan}
                href={ETHERSCAN_TX_BASE_URL + txnHash}
                target='_blank'
                rel='noreferrer'
              >
                <LinkOutlined /> <span>View on etherscan</span>
              </Link>
            </Grid>
          )}
        </Grid>
      </Alert>
    </SnackbarMui>
  );
};

export default Snack;
