import { useCallback } from 'react';
import {
  Dialog as MuiDialog,
  Container,
  Typography,
  Box,
  List,
  Grid,
  Input,
  Divider,
  Button,
} from '@material-ui/core';

import useStyles from '../styles';
import { Nothing, ContainerLoader, TextWithInfoIcon, CloseButton, Switch } from '../../';
import { IListItem } from './ListItems';
import { useTokenSearch } from '../../../hooks';
import {
  useIndexFundTokens,
  useGraphTokenSelection,
  useAutoSelectAction,
  useBurnModals,
} from '../../../state/burn/hooks';
import { IToken } from '../../../utils/generalTypes';

interface ITokenDialog {
  ListItem: React.FC<IListItem>;
  open: boolean;
  close: () => void;
  type?: 'burn' | 'graph';
}

const TokenDialog: React.FC<ITokenDialog> = ({ open, close, ListItem, type = 'burn' }) => {
  const classes = useStyles();

  const { indexFundTokens, selectedIndexFundTokens } = useIndexFundTokens();
  const { indexFundGraphTokens, selectToken } = useGraphTokenSelection();
  const { autoSelect, handleSetAutoSelect } = useAutoSelectAction();
  const { toggleConfirmationModal } = useBurnModals();
  const { filteredTokens, search, handleSearchChange, clearSearchField } = useTokenSearch(
    type === 'burn' ? indexFundTokens : indexFundGraphTokens
  );

  const handleClose = useCallback(() => {
    setTimeout(() => {
      clearSearchField();
    }, 1000 * 0.2);
    close();
  }, [clearSearchField, close]);

  const tokenSelection = useCallback(
    (token: IToken) => {
      selectToken(token);
      handleClose();
    },
    [selectToken, handleClose]
  );

  const handleConfirmation = () => {
    toggleConfirmationModal(true);
    setTimeout(() => {
      handleClose();
    }, 1000 * 0.1);
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth='xl'
    >
      <Container className={classes.container} maxWidth='xs'>
        <Box className={classes.box}>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <Typography variant='h6'>Select Token</Typography>
            </Grid>
            <Grid item>
              <CloseButton onClick={handleClose} />
            </Grid>
          </Grid>

          <Input
            className={classes.input}
            fullWidth
            placeholder='Search by Token name or paste address'
            disableUnderline
            value={search}
            onChange={handleSearchChange}
          />
          {type === 'burn' && (
            <>
              <Box mt={'20px'} />
              <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item>
                  <TextWithInfoIcon title='Only viable tokens will be auto selected'>
                    <Typography>Auto Select</Typography>
                  </TextWithInfoIcon>
                </Grid>
                <Grid item>
                  <Switch value={autoSelect} onChange={handleSetAutoSelect} />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Divider />
        <Box className={classes.listContainer03}>
          {filteredTokens ? (
            filteredTokens.length > 0 ? (
              <List>
                {filteredTokens.map(token => (
                  <ListItem
                    key={token.address}
                    token={token}
                    tokenSelect={type === 'graph' ? tokenSelection : undefined}
                  />
                ))}
              </List>
            ) : (
              <Nothing />
            )
          ) : (
            <Nothing>
              <ContainerLoader />
            </Nothing>
          )}
        </Box>
        {type === 'burn' && (
          <Box
            style={{
              padding: '20px',
              borderTop: '1px solid rgba(255,255,255,0.1)',
              // marginTop: 5,
            }}
          >
            <Box mt={'10px'} />
            <Button
              onClick={handleConfirmation}
              disabled={selectedIndexFundTokens.length === 0}
              fullWidth
              style={{
                textTransform: 'none',
                minHeight: 45,
              }}
              color='primary'
              variant='contained'
            >
              Confirm
            </Button>
          </Box>
        )}
      </Container>
    </MuiDialog>
  );
};

export default TokenDialog;
