import { REACT_APP_ENVIRONMENT, REACT_APP_INFURA_KEY } from './';
import {
  REACT_APP_DEVELOPMENT_PORTIS_ID,
  REACT_APP_FORTMATIC_DEVELOPMENT_KEY,
  REACT_APP_FORTMATIC_PRODUCTION_KEY,
  REACT_APP_PRODUCTION_PORTIS_ID,
} from './envs';

export const DEV_ENVIRONMENT = REACT_APP_ENVIRONMENT === 'DEVELOPMENT';
export const CHAIN_ID = DEV_ENVIRONMENT ? 4 : 1;
export const NETWORK_URL1 = `https://mainnet.infura.io/v3/${REACT_APP_INFURA_KEY}`;
export const NETWORK_URL4 = `https://rinkeby.infura.io/v3/${REACT_APP_INFURA_KEY}`;
export const NETWORK_URL = DEV_ENVIRONMENT ? NETWORK_URL4 : NETWORK_URL1;
export const ETHERSCAN_TX_BASE_URL = DEV_ENVIRONMENT
  ? 'https://rinkeby.etherscan.io/tx/'
  : 'https://etherscan.io/tx/';
export const ETHERSCAN_ADDRESS_BASE_URL = DEV_ENVIRONMENT
  ? 'https://rinkeby.etherscan.io/address/'
  : 'https://etherscan.io/address/';
export const UN_KNOWN_LOGO =
  'https://gateway.pinata.cloud/ipfs/QmTRdZMSSb1EwZE7MY4z91Ms7QgLgsV4WBNY1FD1cYaFPx';
export const PILOT_LOGO =
  'https://gateway.pinata.cloud/ipfs/QmZkwHmYpVL3Bi17SUsmHU4pypkTXan46JH76ToRgoZJQz';
export const FORTMATIC_KEY = DEV_ENVIRONMENT
  ? REACT_APP_FORTMATIC_DEVELOPMENT_KEY
  : REACT_APP_FORTMATIC_PRODUCTION_KEY;
export const PORTIS_KEY = DEV_ENVIRONMENT
  ? REACT_APP_DEVELOPMENT_PORTIS_ID
  : REACT_APP_PRODUCTION_PORTIS_ID;
export const AMOUNT_INPUT_REGEX = /^((\d+[.]?\d*)|(\.\d+))$/;
export const TIME_INTERVAL = 1000 * 30;
export const TOKEN_AMOUNT_THRESHOLD = 100;
export const DEFAULT_DOLLAR_VALUE = DEV_ENVIRONMENT ? '1' : '0';
export const ALLOW_RANDOM_TOKEN_SELECT = !DEV_ENVIRONMENT;
export const TVL_START_DATE = DEV_ENVIRONMENT ? 1635102000 : 1632482500;
