import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import mainBG from '../assets/others/main-bg.png';

export interface CommonColors {
  black: string;
  white: string;
  hoverBC01?: string;
  hoverTC01?: string;
  darkBlackish?: string;
  lightText?: string;
}

interface PaletteOptionsX extends PaletteOptions {
  common?: Partial<CommonColors>;
}

export interface ThemeOptionsX extends ThemeOptions {
  palette?: PaletteOptionsX;
}

export const themeDark = createMuiTheme({
  mixins: {
    toolbar: {
      minHeight: 50,
      ['@media (max-width:999.95px)']: {
        minHeight: 50,
      },
    },
  },
  breakpoints: {
    values: {
      xxs: 280,
      xs: 500,
      sm: 650,
      md: 1000,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    type: 'dark',
    common: {
      black: '#080F19',
      hoverBC01: '#0F1825',
      hoverTC01: '#FFFFFF',
      blackish: '#080F19',
      border: '1px solid rgba(255,255,255,0.1)',
      transparent: 'rgba(0,0,0,0.6)',
      lightText: '#999797',
    },
    primary: {
      main: '#EFA618',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.6)',
    },

    background: {
      paper: 'rgba(0,0,0,0.6)',
      default: '#020E25',
    },
  },

  typography: {
    fontFamily: 'Rubik',
    caption: {
      textTransform: 'none',
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    body2: {
      fontSize: '0.9rem',
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 10,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundImage: `url(${mainBG})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
        },
      },
    },
  },
} as ThemeOptionsX);

export const themeLight = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#EFA618',
    },
    secondary: {
      main: '#F8F8F8',
    },
    background: {
      default: '#F8F8F8',
    },
  },

  typography: {
    fontFamily: 'Rubik',
    caption: {
      textTransform: 'none',
      fontSize: '0.65rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    body2: {
      fontSize: '0.9rem',
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  },
});
