import { useCallback, useMemo } from 'react';
import { Dialog as MuiDialog, Container, Typography, Box, Button } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';

import useStyles from './styles';
import { Header, CGrid, Notification, ListItem, CloseButton } from '../';
import { useUserShare, useBurn, useBurnSend } from '../../hooks';
import { useBurnModals, useIndexFundTokens } from '../../state/burn/hooks';
import { formatDollarAmount } from '../../utils/formating';

interface IConfirmationDialog {
  open: boolean;
  close: () => void;
  type?: 'burn' | 'graph';
}

const ConfirmationDialog: React.FC<IConfirmationDialog> = ({ open, close, type = 'burn' }) => {
  const classes = useStyles();
  const { selectedIndexFundTokens } = useIndexFundTokens();
  const { calculateUserShareValue } = useUserShare();
  const { burnDollarValue } = useBurn();
  const { sendBurnTxn } = useBurnSend();
  const { toggleTokenSelectModal } = useBurnModals();

  const totalTokensDVSum = useMemo<string>(() => {
    let amount = new BigNumber(0);
    selectedIndexFundTokens.forEach(({ balance, usdPrice }) => {
      if (balance && usdPrice) {
        amount = amount.plus(
          new BigNumber(usdPrice).multipliedBy(calculateUserShareValue(balance))
        );
      }
    });
    return amount.toString();
  }, [selectedIndexFundTokens, calculateUserShareValue]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handlerGoBack = useCallback(() => {
    toggleTokenSelectModal(true);
    setTimeout(() => {
      handleClose();
    }, 1000 * 0.1);
  }, [toggleTokenSelectModal, handleClose]);

  const handleBurn = () => {
    handleClose();
    sendBurnTxn();
  };

  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialogPaper }}
      classes={{ paperWidthSm: classes.paperWidthSm }}
      BackdropProps={{
        classes: { root: classes.MUIContainer },
      }}
      maxWidth='xl'
    >
      <Container className={classes.container} maxWidth='xs'>
        <Header
          goBack={handlerGoBack}
          containerStyle={{
            padding: '10px 20px',
            paddingTop: 15,
          }}
          cAlignItems='center'
          iconRight={<CloseButton onClick={close} />}
          elements={<Typography variant='h6'>Confirmation</Typography>}
        />

        <Box padding='0px 20px' mb={'10px'}>
          <Typography variant='h6'>Selected Tokens</Typography>
        </Box>

        <Box className={classes.listContainer03}>
          {selectedIndexFundTokens.map(token => (
            <ListItem key={token.address} token={token} withUserShare showDollarValue />
          ))}
        </Box>
        {type === 'burn' && (
          <>
            <Box className={classes.dashedLine} />
            <Box
              style={{
                padding: '20px',
              }}
            >
              <CGrid
                alignItems='center'
                justify='space-between'
                elements01={<Typography style={{ fontSize: '0.8rem' }}>Total Sum</Typography>}
                elements02={
                  <Typography
                    color='primary'
                    style={{
                      fontSize: '1rem',
                      fontWeight: 600,
                      letterSpacing: '0.7px',
                    }}
                  >
                    {`~ ${formatDollarAmount(parseFloat(totalTokensDVSum))}`}
                  </Typography>
                }
              />
              <Box mt={'15px'} />
              <Notification
                msg={
                  <Typography style={{ fontSize: '0.7rem' }}>
                    You will get{' '}
                    <strong>~ {formatDollarAmount(parseFloat(totalTokensDVSum))}</strong> by burning
                    <strong>
                      {' '}
                      ~ {burnDollarValue ? formatDollarAmount(parseFloat(burnDollarValue)) : '-'}
                    </strong>{' '}
                    Pilot tokens
                  </Typography>
                }
              />
              <Box mt={'20px'} />

              <Button
                onClick={handleBurn}
                disabled={selectedIndexFundTokens.length === 0}
                fullWidth
                style={{
                  textTransform: 'none',
                  minHeight: 45,
                }}
                color='primary'
                variant='contained'
              >
                Burn Now
              </Button>
            </Box>
          </>
        )}
      </Container>
    </MuiDialog>
  );
};

export default ConfirmationDialog;
