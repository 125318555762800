import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mainContainer: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxContainer: {
    padding: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width:550px)': {
      padding: '5rem 0.1rem',
    },
  },
  logo: {
    width: 150,
    height: 150,
    '@media(max-width:550px)': {
      width: 100,
      height: 100,
    },
  },
  subHeading: {
    margin: '10px 0 30px 0',
    '@media(max-width:550px)': {
      margin: '0px 0 20px 0',
      fontSize: '12px',
    },
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    margin: '20px 0',
    '@media(max-width:550px)': {
      fontSize: '22px',
    },
  },
  textPrimary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    letterSpacing: 5,
  },

  timerContainer: {
    margin: '20px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timerItem: {
    boxSizing: 'border-box',
    padding: '4px 8px',
    width: '10% !important',
  },
  timerText: {
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
    '@media(max-width:550px)': {
      fontSize: '24px',
    },
  },
  timerTextSec: {
    fontWeight: 700,
    color: '#fff',
    textAlign: 'center',
    '@media(max-width:550px)': {
      fontSize: '16px',
    },
  },
  disclaimText: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
  },
}));

export default useStyles;
