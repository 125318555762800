import { Box, Container, Grid } from '@material-ui/core';

import useStyles from './styles';
import Add from './Add';
import GBox from './GBox';
import GraphContainer from './GraphContainer';
import { useIndexFundOverallData, useIndexFundTokens } from '../../state/burn/hooks';
import { formatTokenAmount, formatDollarAmount } from '../../utils/formating';
import { useBreakpoints, useIndexFundTVL } from '../../hooks';

const IndexFund: React.FC<{}> = () => {
  const classes = useStyles();

  const { circulatingSupply, totalBurnedPilotTokens } = useIndexFundOverallData();
  const { indexFundTokens } = useIndexFundTokens();
  const { _md } = useBreakpoints();
  const indexFundTvl = useIndexFundTVL();

  const boxInnerContainer = {
    padding: _md ? `0px 10px 15px 10px` : '15px 10px',
  };

  return (
    <>
      <Grid container direction={_md ? 'column-reverse' : 'column'}>
        <Grid
          item
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth={_md ? 'sm' : 'lg'}>
            {_md && <Box mt={'20px'} />}

            <Grid
              className={classes.boxContainer}
              container
              direction={_md ? 'column' : undefined}
              alignItems='stretch'
            >
              <Grid
                item
                style={{
                  padding: '15px 10px 15px 10px',
                }}
                md={3}
                lg={3}
              >
                <GBox
                  text='Index fund value'
                  value={
                    indexFundTvl
                      ? formatDollarAmount(indexFundTvl[indexFundTvl.length - 1][1])
                      : '-'
                  }
                  loading={indexFundTvl === undefined}
                />
              </Grid>
              <Grid item style={boxInnerContainer} md={3} lg={3}>
                <GBox
                  text='No. of tokens'
                  value={
                    indexFundTokens && indexFundTokens.length > 0
                      ? indexFundTokens.length.toString()
                      : '-'
                  }
                  loading={indexFundTokens === undefined}
                />
              </Grid>
              <Grid item style={boxInnerContainer} md={3} lg={3}>
                <GBox
                  text='Pilot tokens burned'
                  value={formatTokenAmount(parseFloat(totalBurnedPilotTokens ?? ''))}
                  loading={totalBurnedPilotTokens === undefined}
                />
              </Grid>
              <Grid item style={boxInnerContainer} md={3} lg={3}>
                <GBox
                  text='Circulating supply'
                  value={formatTokenAmount(parseFloat(circulatingSupply ?? ''))}
                  loading={circulatingSupply === undefined}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {!_md && <Box mt={'24px'} />}
        <Grid item>
          <Container disableGutters maxWidth='lg' style={{ width: '100%' }}>
            <Grid
              container
              direction={_md ? 'column-reverse' : undefined}
              alignItems={_md ? undefined : 'stretch'}
            >
              <Grid item xs={12} sm={12} lg={7} md={7}>
                {_md && <Box mt={'20px'} />}
                <GraphContainer />
              </Grid>
              <Grid item xs={12} sm={12} lg={5} md={5}>
                <Add />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default IndexFund;
