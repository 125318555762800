import { DEV_ENVIRONMENT } from './';

export const CONTRACT_ADDRESSES = DEV_ENVIRONMENT
  ? {
      indexFund: '0xfcBc18e39B4a02D9E040aa3A9295582F5EA7A3aD',
      //indexFund: '0xaBfF3ac119e83824df568C698441cB9AB843e07c',
    }
  : {
      indexFund: '0x95477F96F78EC38916B5457030387D844E886ab3',
    };

export const WETH_ADDRESS = DEV_ENVIRONMENT
  ? '0xc778417e063141139fce010982780140aa0cd5ab'
  : '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
export const PILOT_ADDRESS = '0x37c997b35c619c21323f3518b9357914e8b99525';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
