import { Grid, GridProps } from '@material-ui/core';

interface IRowAlignCenter {
  elements01: React.ReactNode;
  elements02: React.ReactNode;
  justify?: GridProps['justify'];
  containerClassName?: string;
}

export const RowAlignCenter: React.FC<IRowAlignCenter> = ({
  elements01,
  elements02,
  justify,
  containerClassName,
}) => {
  return (
    <Grid
      className={containerClassName}
      container
      alignItems='center'
      justify={justify ? justify : 'space-between'}
    >
      <Grid item>{elements01}</Grid>
      <Grid item>{elements02}</Grid>
    </Grid>
  );
};

interface ICGrid {
  elements01: React.ReactNode;
  elements02: React.ReactNode;
  alignItems?: GridProps['alignItems'];
  justify?: GridProps['justifyContent'];
  direction?: GridProps['direction'];
  containerProps?: GridProps;
  item01Props?: GridProps;
  item02Props?: GridProps;
  containerClassName?: string;
  element01ClassName?: string;
  element02ClassName?: string;
}

export const CGrid: React.FC<ICGrid> = ({
  elements01,
  elements02,
  justify = 'space-between',
  alignItems = 'center',
  direction = 'row',
  containerClassName,
  element01ClassName,
  element02ClassName,
  containerProps,
  item01Props,
  item02Props,
}) => {
  return (
    <Grid
      {...containerProps}
      className={containerClassName}
      container
      alignItems={alignItems}
      justify={justify}
      direction={direction}
    >
      <Grid {...item01Props} className={`${element01ClassName}`} item>
        {elements01}
      </Grid>
      <Grid {...item02Props} className={`${element02ClassName}`} item>
        {elements02}
      </Grid>
    </Grid>
  );
};
