import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';
import { Loud } from '../';

interface INotification {
  msg?: string | JSX.Element;
}

const Notification: React.FC<INotification> = ({
  msg = 'Looks like that you are the first person to provide liqidity in this pool',
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container} alignItems='center' wrap='nowrap'>
      <Grid item>
        <Loud className={classes.iconLoud} />
      </Grid>
      <Grid item>
        <Typography className={classes.text}>{msg}</Typography>
      </Grid>
    </Grid>
  );
};

export default Notification;
