import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Button,
  Box,
  CircularProgress,
  Typography,
  Divider,
} from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import LaunchIcon from '@material-ui/icons/Launch';
import CancelIcon from '@material-ui/icons/Cancel';
import { useWeb3React } from '@web3-react/core';

import useStyles from './styles';
import { Header, CloseButton, Copy, AccountIcon, Truncate } from '../';
import Dialog from './';
import { ETHERSCAN_ADDRESS_BASE_URL, ETHERSCAN_TX_BASE_URL } from '../../constants';
import { useUserTransactions } from '../../state/transaction/hooks';
import { ITransaction } from '../../utils/generalTypes';
import { walletList, walletconnect } from '../../utils/web3Connectors';
import { conciseAddress } from '../../utils/formating';
import { TRANSACTION_STATUS } from '../../utils/enums';

interface IAccountDialog {
  Component: React.FC<any>;
  open: boolean;
  close: () => void;
  addr: string | any;
}

const AccountDialog: React.FC<IAccountDialog> = ({ Component, open, close = () => {}, addr }) => {
  const classes = useStyles();
  const { account, connector, deactivate } = useWeb3React();

  const { transactions, clearUserTransactions } = useUserTransactions();

  const [openWD, setOpenWD] = useState<boolean>(false);

  useEffect(() => {
    if (!Boolean(account) && open) setOpenWD(true);
  }, [account, open]);

  useEffect(() => {
    if (!Boolean(account) && open) close();
  }, [account, openWD]);

  const handleDisconnect = () => {
    deactivate();
    localStorage.removeItem('jwt');
    connector === walletconnect && localStorage.removeItem('walletconnect');
    setOpenWD(true);
  };

  return (
    <>
      {Component && (
        <Component
          open={openWD}
          close={() => {
            setOpenWD(false);
          }}
        />
      )}
      {account && (
        <Dialog open={open} onClose={close}>
          <Container style={{ padding: '15px 15px' }} maxWidth='xs'>
            <Header
              disableGoBack
              cAlignItems='center'
              elements={<Typography variant='body1'>Account</Typography>}
              iconRight={<CloseButton onClick={close} />}
            />
            <Box className={classes.accContainer}>
              <Box className={classes.marginTop} />
              <Grid container alignItems='center' justify='space-between'>
                {account && (
                  <Grid item>
                    <Typography className={classes.secondaryText}>
                      Connected with{' '}
                      {
                        walletList.filter(wallet => connector instanceof wallet.connectorType)[0]
                          .name
                      }
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button
                    className={classes.acBtn}
                    onClick={() => {
                      setOpenWD(true);
                    }}
                    color='primary'
                    variant='outlined'
                  >
                    Change
                  </Button>
                </Grid>
              </Grid>

              <Box className={classes.addrSec}>
                <Grid container item alignItems='center'>
                  <Grid item className={classes.userAddressContainer}>
                    <AccountIcon />
                    <Typography variant='body1'>{conciseAddress(addr)}</Typography>
                  </Grid>
                  <Grid item>
                    <Copy value={addr} />
                  </Grid>
                </Grid>
              </Box>

              <Box style={{ marginTop: '5px' }}>
                <a
                  href={ETHERSCAN_ADDRESS_BASE_URL + account}
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                >
                  <Button
                    className={classes.acBtn}
                    style={{ width: '130px', marginRight: '10px' }}
                    color='primary'
                    variant='outlined'
                  >
                    View on etherscan
                  </Button>
                </a>

                <Button
                  className={classes.acBtn}
                  style={{ width: '90px' }}
                  onClick={handleDisconnect}
                  color='primary'
                  variant='outlined'
                >
                  Disconnect
                </Button>
              </Box>

              <Box className={classes.marginTop} />

              <Grid container alignItems='center' justify='space-between'>
                <Grid item>
                  <Typography style={{ fontSize: '0.8rem', letterSpacing: '0.01rem' }}>
                    {account && transactions.length > 0
                      ? 'Recent Transactions'
                      : 'Your last transactions will appear here'}
                  </Typography>
                </Grid>
                {account && transactions.length > 0 && (
                  <Grid item>
                    <Button
                      style={{
                        textTransform: 'none',
                        fontSize: '0.9rem',
                      }}
                      color='primary'
                      variant='text'
                      onClick={() => clearUserTransactions(account)}
                    >
                      Clear all
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Box className={classes.txnBox}>
                {account &&
                  transactions.length > 0 &&
                  transactions.map((tx: ITransaction, idx) => (
                    <>
                      <Trx key={idx} tx={tx} />
                      {idx !== transactions.length - 1 && <Divider />}
                    </>
                  ))}
              </Box>
            </Box>
          </Container>
        </Dialog>
      )}
    </>
  );
};

export default AccountDialog;

const Trx: React.FC<{ tx: ITransaction }> = ({ tx }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.trx} container alignItems='center' justify='space-between'>
      <Grid item>
        <Grid container alignItems='center' spacing={1}>
          <Grid item>
            <Truncate className={classes.txMsg} text={tx.message} len={30} mobileLen={20} />
          </Grid>
          <Grid item>
            <a
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              href={ETHERSCAN_TX_BASE_URL + tx.hash}
              target='_blank'
            >
              <LaunchIcon color='primary' style={{ fontSize: '0.9rem' }} />
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {tx.status === TRANSACTION_STATUS.SUCCESS ? (
          <CheckCircleOutlinedIcon
            style={{ width: 18, height: 18, marginTop: 5 }}
            color='primary'
          />
        ) : tx.status === TRANSACTION_STATUS.PENDING ? (
          <CircularProgress style={{ width: 18, height: 18, marginTop: 5 }} />
        ) : (
          <CancelIcon style={{ width: 18, height: 18, marginTop: 5 }} color='primary' />
        )}
      </Grid>
    </Grid>
  );
};
