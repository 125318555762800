import { useCallback, useMemo, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "bignumber.js";

import { useIndexFundTokenSelection } from "./";
import { useTokenApproval } from "../contracts/hooks/erc20Hooks";
import { useWithraw } from "../contracts/hooks/indexFundHooks";
import {
  usePilotToken,
  usePilotBurnAmount,
  useIndexFundOverallData,
  useIndexFundTokens,
  useAutoSelectAction,
  useBurnModals,
} from "../state/burn/hooks";
import { useLoading, usePilotPirce, useGas } from "../state/application/hooks";
import { validAmount } from "../utils/helpers";
import { formatAmount, parseAmount } from "../utils/formating";
import { CHAIN_ID, ALLOW_RANDOM_TOKEN_SELECT } from "../constants";
import { IToken } from "../utils/generalTypes";

// handle pilot amount input field
export const useBurnInput = () => {
  const { pilotBurnAmount, updatePilotBurnAmount } = usePilotBurnAmount();
  const { pilotToken } = usePilotToken();
  const { autoSelect, shouldUpdateAutoSelect, handleShouldUpdateAutoSelect } =
    useAutoSelectAction();

  const invalidPilotBurnAmount = useMemo<boolean>(
    () => (pilotBurnAmount ? validAmount(pilotToken, pilotBurnAmount) : false),
    [pilotToken, pilotBurnAmount, validAmount]
  );

  //update autoselect list on amount change
  const updateAutoSelectList = useCallback(() => {
    if (!autoSelect && !shouldUpdateAutoSelect) {
      handleShouldUpdateAutoSelect(true);
    }
  }, [autoSelect, shouldUpdateAutoSelect, handleShouldUpdateAutoSelect]);

  const handlePilotBurnAmountChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      updatePilotBurnAmount(value);
      updateAutoSelectList();
    },
    [updatePilotBurnAmount, updateAutoSelectList]
  );

  const clearPilotBurnAmount = useCallback(() => {
    updatePilotBurnAmount("");
  }, [updatePilotBurnAmount]);

  const handleMaxPilotBurnAmount = useCallback(() => {
    pilotToken.balance &&
      updatePilotBurnAmount(
        formatAmount(pilotToken.balance, pilotToken.decimals)
      );
    updateAutoSelectList();
  }, [
    pilotToken.balance,
    pilotToken.decimals,
    formatAmount,
    updatePilotBurnAmount,
    updateAutoSelectList,
  ]);

  return {
    pilotBurnAmount,
    invalidPilotBurnAmount,
    handlePilotBurnAmountChange,
    clearPilotBurnAmount,
    handleMaxPilotBurnAmount,
  };
};

// handle burn section conditional UI
export const useBurn = () => {
  const { account, chainId } = useWeb3React();
  const { pilotToken } = usePilotToken();
  const { dappLoading } = useLoading();
  const { invalidPilotBurnAmount, pilotBurnAmount } = useBurnInput();
  const { selectedIndexFundTokens } = useIndexFundTokenSelection();
  const { approved, approveToken } = useTokenApproval(pilotToken);
  const { indexFundDollarValue } = useIndexFundOverallData();

  const { pilotPrice } = usePilotPirce();
  const { handleAutoSelect } = useAutoSelect();
  const { userShare } = useUserShare();
  const { toggleConnectModal, toggleTokenSelectModal } = useBurnModals();

  const burnLabel = useMemo<string>(() => {
    if (account) {
      if (chainId !== CHAIN_ID)
        return `Change network to ${CHAIN_ID === 1 ? "mainnet" : "rinkeby"}`;
      else if (approved !== undefined && !approved)
        return `Approve ${pilotToken.symbol}`;
      else if (dappLoading) return dappLoading;
      else if (!(parseFloat(pilotBurnAmount) > 0)) return "Enter an amount";
      else if (!userShare) return "Amount exceeds circulating supply";
      else if (invalidPilotBurnAmount) return "Insufficient balance";
      else return `Burn`;
    } else return "Connect Wallet";
  }, [
    account,
    chainId,
    approved,
    pilotBurnAmount,
    invalidPilotBurnAmount,
    dappLoading,
    selectedIndexFundTokens,
    userShare,
  ]);

  const burnDisable = useMemo(() => {
    if (!account) return false;
    if (chainId !== CHAIN_ID) return true;
    else if (approved !== undefined && !approved) return false;
    else if (parseFloat(pilotBurnAmount) > 0 && !invalidPilotBurnAmount)
      return false;
    else return true;
  }, [
    account,
    chainId,
    approved,
    pilotBurnAmount,
    invalidPilotBurnAmount,
    selectedIndexFundTokens,
  ]);

  const burnDollarValue = useMemo<string | undefined>(() => {
    if (parseFloat(pilotBurnAmount) > 0 && userShare) {
      return new BigNumber(pilotBurnAmount).multipliedBy(pilotPrice).toString();
    }
  }, [pilotBurnAmount, userShare]);

  const returnAmount = useMemo<string | undefined>(() => {
    if (userShare) {
      const ifDV = new BigNumber(
        indexFundDollarValue ? indexFundDollarValue : "0"
      );
      return ifDV.multipliedBy(userShare).toString();
    }
  }, [indexFundDollarValue, userShare]);

  const returnPercentage = useMemo(() => {
    if (burnDollarValue && returnAmount) {
      return new BigNumber(returnAmount)
        .dividedBy(burnDollarValue)
        .multipliedBy(100)
        .toString();
    }
  }, [burnDollarValue, returnAmount]);

  const burnAction = useCallback(async () => {
    if (!account) {
      toggleConnectModal(true);
    } else if (!approved) {
      await approveToken();
    } else {
      handleAutoSelect();
      toggleTokenSelectModal(true);
    }
  }, [account, approved, approveToken, burnDollarValue, burnDollarValue]);

  return {
    burnLabel,
    burnAction,
    burnDisable,
    burnDollarValue,
    returnAmount,
    returnPercentage,
  };
};

// send burn txn
export const useBurnSend = () => {
  const { account } = useWeb3React();
  const { selectedIndexFundTokens } = useIndexFundTokenSelection();
  const { withdrawTokens } = useWithraw();
  const { pilotToken, updatePilotBalance } = usePilotToken();
  const { pilotBurnAmount, clearPilotBurnAmount } = useBurnInput();
  const { burnDollarValue } = useBurn();
  const { getIFOverallData } = useIndexFundOverallData();

  const sendBurnTxn = useCallback(async () => {
    if (account) {
      const tokenAddresses = selectedIndexFundTokens.map(
        ({ address }) => address
      );
      const pilotAmount = parseAmount(pilotBurnAmount, pilotToken.decimals);
      await withdrawTokens(
        tokenAddresses,
        pilotAmount,
        burnDollarValue ?? "--",
        () => {
          updatePilotBalance(account);
          clearPilotBurnAmount();
          getIFOverallData();
        }
      );
    }
  }, [
    account,
    selectedIndexFundTokens,
    burnDollarValue,
    pilotBurnAmount,
    pilotToken.decimals,
    withdrawTokens,
    updatePilotBalance,
    clearPilotBurnAmount,
    getIFOverallData,
  ]);

  return { sendBurnTxn };
};

// calculate user share w.r.t entered pilot amount / circulating supply
export const useUserShare = () => {
  const { circulatingSupply } = useIndexFundOverallData();
  const { pilotBurnAmount } = useBurnInput();

  const userShare = useMemo<string | undefined>(() => {
    if (
      circulatingSupply &&
      pilotBurnAmount &&
      parseFloat(pilotBurnAmount) < parseFloat(circulatingSupply)
    ) {
      const share = new BigNumber(pilotBurnAmount).dividedBy(circulatingSupply);
      return share.toString();
    }
  }, [circulatingSupply, pilotBurnAmount]);

  // find user share amount from give total amount
  const calculateUserShareValue = useCallback(
    (value: string) => {
      const amount = new BigNumber(value);
      return amount.multipliedBy(userShare ?? "0").toString();
    },
    [userShare]
  );

  return { userShare, calculateUserShareValue };
};

//handling random token selection
export const useFix = () => {
  const { indexFundTokens } = useIndexFundTokens();
  const { pilotBurnAmount } = useBurnInput();

  const selectRandomTokens = useCallback(
    (autoSelectedTokens: IToken[]) => {
      if (indexFundTokens && parseFloat(pilotBurnAmount) >= 20) {
        const tempTokens = [...autoSelectedTokens];
        const addresses = tempTokens.map(({ address }) => address);
        while (tempTokens.length < 2) {
          const randomIndex = Math.floor(
            Math.random() * indexFundTokens.length
          );
          const token = indexFundTokens[randomIndex];
          if (!addresses.includes(token.address)) {
            tempTokens.push(token);
            addresses.push(token.address);
          }
        }
        return tempTokens;
      } else return autoSelectedTokens;
    },
    [indexFundTokens]
  );

  return selectRandomTokens;
};

// handle autoselect functionality
export const useAutoSelect = () => {
  const { indexFundTokens } = useIndexFundTokens();
  const { autoSelect, autoSelectingTokens, shouldUpdateAutoSelect } =
    useAutoSelectAction();
  const { userShare } = useUserShare();
  const { gasPrice } = useGas();
  const selectRandomTokens = useFix();

  //token dollar amount w.r.t userShare
  const userTokenShareDollarValue = useCallback(
    (token: IToken, share: string) => {
      const tokenShare = new BigNumber(token.balance ?? "0").multipliedBy(
        share
      );
      return tokenShare.multipliedBy(token.usdPrice ?? "0");
    },
    []
  );

  //total dollar amount for viable tokens
  // const returnDollarValue = useMemo<string | undefined>(() => {
  //   if (indexFundTokens && userShare) {
  //     let amount = new BigNumber(0);
  //     indexFundTokens.forEach(token => {
  //       const tokenShareValue = userTokenShareDollarValue(token, userShare);
  //       if (tokenShareValue.isGreaterThan(gasPrice)) {
  //         amount = amount.plus(tokenShareValue);
  //       }
  //     });
  //     return amount.toString();
  //   }
  // }, [indexFundTokens, gasPrice, userShare]);

  const handleAutoSelect = useCallback(() => {
    if (
      indexFundTokens &&
      userShare &&
      (autoSelect || shouldUpdateAutoSelect)
    ) {
      const autoSelectedTokens = indexFundTokens?.filter((token) =>
        userTokenShareDollarValue(token, userShare).isGreaterThan(gasPrice)
      );
      const tempTokens = ALLOW_RANDOM_TOKEN_SELECT
        ? selectRandomTokens(autoSelectedTokens)
        : autoSelectedTokens;

      autoSelectingTokens(tempTokens);
    }
  }, [indexFundTokens, userShare, autoSelectingTokens, shouldUpdateAutoSelect]);

  return { userShare, handleAutoSelect };
};

// handle autoSelect tooltip UI
export const useAutoSelectToolTip = () => {
  const { autoSelect } = useAutoSelectAction();
  const [showTooltip, setShowTooltip] = useState<boolean>(autoSelect);

  const handleTooltipChange = useCallback(
    (value: boolean) => {
      setShowTooltip(value);
    },
    [setShowTooltip]
  );

  useEffect(() => {
    if (autoSelect) {
      setShowTooltip(true);
      const timer = setTimeout(() => setShowTooltip(false), 5000);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowTooltip(false);
    }
  }, [autoSelect]);

  return { showTooltip, handleTooltipChange };
};
