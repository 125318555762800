import { useCallback } from 'react';

import { AppState } from '../';
import { useAppDispatch, useAppSelector } from '../hooks';
import { SNACKBAR_SEVERITY, MODAL_VARIANT } from '../../utils/enums';
import {
  showSnackbar,
  hideSnackbar,
  showModal,
  hideModal,
  updateLoading,
  setPilotPirce,
  currentGasPrice,
} from './actions';

export const useSnackbar = () => {
  const snackbar = useAppSelector((state: AppState) => state.application.snackbar);
  const dispatch = useAppDispatch();

  const showSnackbarF = useCallback(
    (severity: SNACKBAR_SEVERITY, message: string, txnHash?: string) => {
      dispatch(showSnackbar({ severity, message, txnHash }));
    },
    [dispatch, showSnackbar]
  );

  const hideSnackbarF = useCallback(() => {
    dispatch(hideSnackbar());
  }, [dispatch, hideSnackbar]);

  return { ...snackbar, showSnackbarF, hideSnackbarF };
};

export const useModal = () => {
  const modal = useAppSelector((state: AppState) => state.application.modal);
  const dispatch = useAppDispatch();

  const showModalF = useCallback(
    (variant: MODAL_VARIANT, txnHash?: string) => {
      dispatch(showModal({ variant, txnHash }));
    },
    [dispatch, showModal]
  );

  const hideModalF = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch, hideModal]);

  return { ...modal, showModalF, hideModalF };
};

export const useLoading = () => {
  const dappLoading = useAppSelector((state: AppState) => state.application.dappLoading);
  const dispatch = useAppDispatch();

  const updateLoadingF = useCallback(
    (loading: string) => {
      dispatch(updateLoading(loading));
    },
    [dispatch, updateLoading]
  );

  return { dappLoading, updateLoadingF };
};

export const usePilotPirce = () => {
  const pilotPrice = useAppSelector((state: AppState) => state.application.pilotPrice);
  const dispatch = useAppDispatch();

  const setPilotPriceF = useCallback(() => {
    dispatch(setPilotPirce());
  }, [dispatch, setPilotPirce]);

  return { pilotPrice, setPilotPriceF };
};

export const useGas = () => {
  const gasPrice = useAppSelector((state: AppState) => state.application.gasPrice);
  const dispatch = useAppDispatch();

  const updateGasPrice = useCallback(() => {
    dispatch(currentGasPrice());
  }, [dispatch, currentGasPrice]);

  return { gasPrice, updateGasPrice };
};
