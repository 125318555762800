import { useMemo, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import useStyles from "./styles";
import {
  LineChart,
  // ButtonDropIcon,
  // TokenLogo,
  //TokenDialog,
  //TokenListItem,
  Nothing,
  ContainerLoader,
} from "../../components";
//import { useGraphTokenSelection } from '../../state/burn/hooks';
import { useIndexFundTVL } from "../../hooks";
import { formatDollarAmount } from "../../utils/formating";

const GraphContainer: React.FC<{}> = () => {
  const classes = useStyles();
  const theme = useTheme();

  const _md = useMediaQuery(theme.breakpoints.down("md"));
  const _sm = useMediaQuery(theme.breakpoints.down("sm"));
  //const { graphToken, tokenChartData } = useGraphTokenSelection();
  const indexFundTvl = useIndexFundTVL();

  //const [open, setOpen] = useState<boolean>(false);
  const balanceRef = useRef<HTMLSpanElement>(null);
  const timeRef = useRef<HTMLSpanElement>(null);

  // const currentTokenBalance = useMemo<string>(() => {
  //   return tokenChartData && tokenChartData.length > 0
  //     ? formatTokenAmount(tokenChartData[tokenChartData.length - 1][1])
  //     : '';
  // }, [tokenChartData]);

  const currentTvl = useMemo<string>(() => {
    return indexFundTvl && indexFundTvl.length > 0
      ? formatDollarAmount(indexFundTvl[indexFundTvl.length - 1][1])
      : "";
  }, [indexFundTvl]);

  return (
    <>
      {/* <TokenDialog open={open} close={() => setOpen(false)} ListItem={TokenListItem} type='graph' /> */}
      <Container
        style={{
          height: _sm ? "none" : "100%",
          boxSizing: "border-box",
        }}
        maxWidth={_sm ? "sm" : "lg"}
      >
        <Grid
          container
          style={{
            padding: "20px",
            paddingBottom: 10,
            boxSizing: "border-box",
          }}
          className={classes.graphContainer}
          direction="column"
          justify="space-between"
        >
          <Grid item>
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                {/* <ButtonDropIcon
                  onClick={() => setOpen(true)}
                  className={classes.buttonContainerDropDown}
                  aria-controls='simple-menu'
                  aria-haspopup='true'
                  fullWidth
                >
                  <Grid container alignItems='center'>
                    <Grid
                      item
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <TokenLogo uri={graphToken.logoURI} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant='body2'
                        style={{ fontSize: '0.8rem', fontWeight: 500 }}
                        className={classes.buttonContent}
                      >
                        {graphToken.symbol}
                      </Typography>
                    </Grid>
                  </Grid>
                </ButtonDropIcon> */}
                <Typography variant="h5">TVL</Typography>
              </Grid>
              <Grid item>
                {indexFundTvl && indexFundTvl.length > 0 && (
                  <Box>
                    <Typography ref={balanceRef} variant="h5">
                      {currentTvl}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
            {indexFundTvl && indexFundTvl.length > 0 && (
              <Box className={classes.timeBox}>
                <Typography ref={timeRef} variant="body2"></Typography>
              </Box>
            )}
          </Grid>
          <Grid item>
            {_md && <Box mt={"20px"} />}
            {indexFundTvl ? (
              indexFundTvl.length > 0 ? (
                <LineChart
                  data={indexFundTvl}
                  defaultValue={currentTvl}
                  valueRef={balanceRef}
                  labelRef={timeRef}
                />
              ) : (
                <Nothing
                  containerStyle={{
                    minHeight: "300px",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 50,
                  }}
                />
              )
            ) : (
              <Nothing
                containerStyle={{
                  minHeight: "390px",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingBottom: 80,
                  display: "flex",
                }}
              >
                <ContainerLoader />
              </Nothing>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default GraphContainer;
