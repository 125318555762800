import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import { Logo, LogoTick, AccountDialog, WalletConnectDialog, TokenLogo, AccountIcon } from '../';
import useStyles from './styles';
import { conciseAddress } from '../../utils/formating';
import { getNetworkName } from '../../utils/helpers';
import { CHAIN_ID, PILOT_LOGO, PILOT_ADDRESS } from '../../constants';
import { usePilotPirce } from '../../state/application/hooks';

interface IMainHeader {
  bottom?: boolean;
}

const MainHeader: React.FC<IMainHeader> = ({ bottom = false }) => {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();
  const theme = useTheme();
  const sm_ = useMediaQuery(theme.breakpoints.down('sm'));
  const xs_ = useMediaQuery(theme.breakpoints.down('xs'));

  const { pilotPrice } = usePilotPirce();

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <AccountDialog
        addr={account ? account : ''}
        Component={WalletConnectDialog}
        open={open}
        close={() => setOpen(false)}
      />
      <AppBar
        className={`${bottom ? classes.goToBottom : classes.appBar}`}
        elevation={0}
        style={{ padding: bottom ? undefined : '0 24px' }}
        color={'transparent'}
        position={bottom ? 'fixed' : 'static'}
      >
        <Container
          style={{ maxWidth: bottom ? undefined : '1500px' }}
          className={classes.container}
          maxWidth='xl'
        >
          <Toolbar className={bottom ? classes.toolBarAfter : classes.toolBarBefore}>
            {sm_ ? (
              <Link to='/'>
                <LogoTick className={xs_ ? classes.logoTick_xs : classes.logoTick} />
              </Link>
            ) : (
              <Link to='/' style={{ display: 'flex', justifyContent: 'center' }}>
                <Logo className={classes.logo} />
              </Link>
            )}

            <Box marginLeft='auto' display='flex' alignItems='center'>
              {!xs_ && (
                <a
                  href={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${PILOT_ADDRESS}`}
                  target='_blank'
                  className={classes.pilotPrice}
                >
                  <TokenLogo className={classes.pilotLogo} uri={PILOT_LOGO} />
                  <Typography variant='body2' className={classes.pilotText}>
                    Pilot
                  </Typography>
                  {pilotPrice !== '' ? (
                    <Typography variant='body2'>{`$ ${pilotPrice}`}</Typography>
                  ) : (
                    <Skeleton width={80} height={30} />
                  )}
                </a>
              )}

              {account && !xs_ && (
                <Typography
                  className={`${classes.specialContent} ${
                    chainId !== CHAIN_ID ? classes.inValidChain : ''
                  }`}
                  color='primary'
                >
                  {getNetworkName(chainId)}
                </Typography>
              )}

              <Button
                onClick={handleOpen}
                style={{
                  padding: 0,
                  marginLeft: 15,
                  textTransform: 'capitalize',
                }}
              >
                <Grid container className={classes.contentContainer} alignItems='center'>
                  <Grid item>
                    <AccountIcon />
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontWeight: 500,
                        textTransform: 'none',
                        fontSize: '0.9rem',
                        marginLeft: account ? '5px' : '0',
                      }}
                    >
                      {account ? conciseAddress(account) : 'Connect Wallet'}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default MainHeader;
