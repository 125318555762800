import axios from 'axios';

import { UN_KNOWN_LOGO } from '../constants';
import { logError } from '../utils/logs';

export const getTokenLogo = async (uri: string): Promise<string> => {
  let _uri = uri;
  try {
    if (uri.startsWith('ipfs')) {
      const _val = uri?.split('//');
      _uri = 'https://ipfs.io/ipfs/' + _val[1];
    }
    const res = await axios.get(_uri);
    if (res) {
      return _uri;
    } else {
      return UN_KNOWN_LOGO;
    }
  } catch (error) {
    logError('Token Logo', error);
    return UN_KNOWN_LOGO;
  }
};

export interface IDollarValue {
  [key: string]: { usd: number };
}

export const fetchTokensDollarValue = async (tokenAddresses: string[]) => {
  try {
    const uri = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${tokenAddresses}&vs_currencies=USD`;
    const res = await axios.get<IDollarValue>(uri);
    if (res) {
      return res.data;
    }
  } catch (e) {
    logError('fetchTokenDollarValue', e);
  }
};
