import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';

import { fectIndexFundOverallData, fetchTokensDollarValue } from '../../data';
import { IToken } from '../../utils/generalTypes';
import { getLogoUri } from '../../utils/helpers';
import { formatAmount, exponentialToDecimal } from '../../utils/formating';
import { ZERO_ADDRESS, WETH_ADDRESS, DEFAULT_DOLLAR_VALUE } from '../../constants';

export const setPilotBalance = createAction<string>('burn/pilotBalance');

export const setPilotBurnAmount = createAction<string>('burn/pilotBurnAmount');

export const selectIndexFundToken = createAction<IToken>('burn/selectIndexFundToken');

export const unselectIndexFundToken = createAction<string>('burn/unselectIndexFundToken');

export const clearSelectedIndexFundTokens = createAction<void>('burn/clearSelectedIndexFundTokens');

export const setGraphToken = createAction<IToken>('burn/setGraphToken');

export const autoSelectTokens = createAction<IToken[]>('burn/autoSelectTokens');

export const setAutoSelect = createAction<boolean>('burn/setAutoSelect');

export const setConnectModal = createAction<boolean>('burn/toggleModals');

export const setTokenSelectModal = createAction<boolean>('burn/setTokenSelectModal');

export const setConfirmationModal = createAction<boolean>('burn/setConfirmationModal');

export const setShouldUpdateAutoSelect = createAction<boolean>('burn/setShouldUpdateAutoSelect');

const getIndexFundValue = (indexFundTokens: IToken[]) => {
  let indexFundDollarValue = new BigNumber(0);
  indexFundTokens.forEach(token => {
    const tokenReserves = new BigNumber(token.balance ?? '0');
    const tokenDollarValue = new BigNumber(token.usdPrice ?? '1');
    indexFundDollarValue = indexFundDollarValue.plus(tokenReserves.multipliedBy(tokenDollarValue));
  });
  return indexFundDollarValue.toString();
};

export const getIndexFundOverallData = createAsyncThunk(
  'burn/indexFundOverallData',
  async ({ circulatingSupply, eth }: { circulatingSupply: string; eth: IToken }) => {
    let allIndexFundTokens: IToken[] = [];
    let totalBurnedPilotTokens = '';
    let indexFundDollarValue = '';
    const res = await fectIndexFundOverallData();
    if (res) {
      const dollarValues = await fetchTokensDollarValue([
        ...res.indexTokens.map(({ id }) => id),
        WETH_ADDRESS,
      ]);
      // totalBurnedPilotTokens =
      //   res.pilotBurneds.length > 0 ? formatAmount(res.pilotBurneds[0].totalPilotBurned, 18) : '0';

      let tempFix = 0;
      (res.pilotBurnedTransactions ?? []).forEach(({ pilotBurned }) => {
        tempFix += Number(pilotBurned);
      });

      totalBurnedPilotTokens = formatAmount(exponentialToDecimal(tempFix.toString(), false), 18);

      allIndexFundTokens = res.indexTokens.map(
        ({ id, symbol, name, decimals, currentIndexAmount }) => {
          return {
            address: id,
            symbol,
            name,
            decimals: parseFloat(decimals),
            balance: currentIndexAmount,
            logoURI: getLogoUri(id),
            usdPrice:
              dollarValues && dollarValues.hasOwnProperty(id)
                ? dollarValues[id].usd.toString()
                : DEFAULT_DOLLAR_VALUE,
            //: 0,
          };
        }
      );
      const ethExists = allIndexFundTokens.filter(({ address }) => address === ZERO_ADDRESS)[0];
      if (ethExists || parseFloat(eth.balance ?? '0') > 0) {
        const ethWithBalance = {
          ...eth,
          usdPrice:
            dollarValues && dollarValues.hasOwnProperty(WETH_ADDRESS)
              ? dollarValues[WETH_ADDRESS].usd.toString()
              : DEFAULT_DOLLAR_VALUE,
        };
        allIndexFundTokens = [
          ethWithBalance,
          ...allIndexFundTokens.filter(({ address }) => address !== ZERO_ADDRESS),
        ];
      }

      indexFundDollarValue = getIndexFundValue(allIndexFundTokens);
    }
    return {
      allIndexFundTokens,
      totalBurnedPilotTokens,
      circulatingSupply,
      indexFundDollarValue,
    };
  }
);
