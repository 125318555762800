import { DEV_ENVIRONMENT } from '../constants';

const logMessage = (title: string, ...params: any): void => {
  if (true) {
    console.log(title, ' -> ', ...params, ' -> ', Date.now());
  }
};

const logError = (title: string, ...params: any): void => {
  if (true) {
    console.error('ERROR ', title, ' -> ', ...params, ' -> ', Date.now());
  }
};

export { logMessage, logError };
