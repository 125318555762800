import { usePilotPirce, useGas } from './hooks';
import { useInterval } from '../../hooks';
import { TIME_INTERVAL } from '../../constants';

const Updater = (): null => {
  const { setPilotPriceF } = usePilotPirce();
  const { updateGasPrice } = useGas();

  useInterval(
    () => {
      setPilotPriceF();
      updateGasPrice();
    },
    TIME_INTERVAL,
    []
  );

  return null;
};

export default Updater;
