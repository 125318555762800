import { useState, useMemo, useCallback } from 'react';

import { useIndexFundTokens } from '../state/burn/hooks';
import { IToken } from '../utils/generalTypes';
import { isAddress } from '../utils/formating';

export const useIndexFundTokenSelection = () => {
  const { selectedIndexFundTokens, selectIFToken, unSelectIFToken } = useIndexFundTokens();

  const isTokenSelected = useCallback(
    (tokenAddress: string) => {
      return selectedIndexFundTokens.find(({ address }) => address === tokenAddress) ? true : false;
    },
    [selectedIndexFundTokens]
  );

  const handleTokenSelect = useCallback(
    (token: IToken) => {
      if (isTokenSelected(token.address)) unSelectIFToken(token.address);
      else selectIFToken(token);
    },
    [isTokenSelected, selectIFToken, unSelectIFToken]
  );

  return { isTokenSelected, selectedIndexFundTokens, handleTokenSelect };
};

export const useTokenSearch = (tokens: IToken[] | undefined) => {
  const [search, setSearch] = useState<string>('');

  const filteredTokens = useMemo<IToken[] | undefined>(() => {
    return tokens?.filter(
      token =>
        token.symbol.toLowerCase().includes(search.toLowerCase()) ||
        token.name.toLowerCase().includes(search.toLowerCase()) ||
        (isAddress(search) && token.address.toLowerCase() === search.toLowerCase())
    );
  }, [search, tokens, isAddress]);

  const handleSearchChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(value);
    },
    [setSearch]
  );

  const clearSearchField = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  return { filteredTokens, search, handleSearchChange, clearSearchField };
};
