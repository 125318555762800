import gql from 'graphql-tag';

export const INDEX_FUND_TOKENS = gql`
  query indexTokens {
    indexTokens(
      where: { currentIndexAmount_gt: 0 }
      orderBy: currentIndexAmount
      orderDirection: desc
      subgraphError: allow
    ) {
      id
      symbol
      name
      decimals
      currentIndexAmount
    }
  }
`;

export const INDEX_FUND_OVERALL_DATA = gql`
  query indexFundOverallData {
    indexTokens(orderBy: currentIndexAmount, orderDirection: desc, subgraphError: allow) {
      id
      symbol
      name
      decimals
      currentIndexAmount
    }

    pilotBurneds(subgraphError: allow) {
      totalPilotBurned
    }

    pilotBurnedTransactions(subgraphError: allow) {
      pilotBurned
    }
  }
`;

export const INDEX_FUND_TOKENS_DAY_DATA = gql`
  query tokenChartDays($startTime: Int!, $address: Bytes!) {
    tokenChartDays(
      orderBy: timestamp
      where: { indexTokenId: $address, timestamp_gt: $startTime }
      orderDirection: asc
      subgraphError: allow
    ) {
      id
      currentIndexAmount
      timestamp
    }
  }
`;

export const INDEX_FUND_TVL_DATA = gql`
  query tokenChartTransactions {
    tokenChartTransactions(orderBy: timestamp) {
      indexAmount
      timestamp
      indexTokenId {
        id
      }
    }
  }
`;

export const TVL_DAY_DATA = gql`
  query tvldays($startTime: Int!, $skip: Int!) {
    tvldays(
      first: 1000
      orderBy: timestamp
      where: { timestamp_gt: $startTime }
      orderDirection: asc
      skip: $skip
      subgraphError: allow
    ) {
      id
      currentTVL
      timestamp
    }
  }
`;
