import { makeStyles } from '@material-ui/core';

const useStylesInputMax = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 500,
    padding: '4px 15px',
    borderRadius: theme.shape.borderRadius,
  },
  maxButton: {
    cursor: 'pointer',
    textTransform: 'lowercase',
    fontSize: '0.7rem',
    paddingTop: 20,
    '&:hover': {
      color: 'white',
    },
  },
  input: {
    fontSize: '1.5rem',
    paddingTop: 0,
    paddingBottom: 10,
    margin: 0,
    minHeight: 25,
  },
  error: {
    color: 'red',
  },
  inputMain: {
    textAlign: 'right',
  },
}));

export default useStylesInputMax;
