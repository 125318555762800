import { Grid, Input, Typography } from '@material-ui/core';

import useStylesInputMax from './styles';
import { useBurnInput } from '../../hooks';

interface IInputMaxProps {
  style?: React.CSSProperties;
  className?: string;
}

const InputMax: React.FC<IInputMaxProps> = ({ style, className }) => {
  const classes = useStylesInputMax();
  const {
    pilotBurnAmount,
    invalidPilotBurnAmount,
    handlePilotBurnAmountChange,
    handleMaxPilotBurnAmount,
  } = useBurnInput();

  return (
    <>
      <Grid container style={{ padding: '0 20px' }} alignItems='center' justifyContent='flex-end'>
        <Typography
          onClick={handleMaxPilotBurnAmount}
          className={classes.maxButton}
          variant='body2'
          color='primary'
        >
          max
        </Typography>
      </Grid>
      <Input
        style={style}
        fullWidth
        className={[
          className ?? classes.container,
          invalidPilotBurnAmount ? classes.error : '',
          classes.input,
        ].join(' ')}
        disableUnderline
        id='standard-adornment-amount'
        placeholder='0.0'
        value={pilotBurnAmount}
        onChange={handlePilotBurnAmountChange}
        autoComplete='off'
        classes={{
          input: classes.inputMain,
        }}
      />
    </>
  );
};

export default InputMax;
