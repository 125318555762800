import {
  Container,
  Typography,
  DialogContent,
  CircularProgress,
  Grid,
  Link,
  Box,
} from '@material-ui/core';
import { LinkOutlined } from '@material-ui/icons';

import useStyles from './styles';
import { Check, Remove, Block, CloseButton } from '..';
import Dialog from '.';
import { ETHERSCAN_TX_BASE_URL } from '../../constants';
import { useModal } from '../../state/application/hooks';
import { MODAL_VARIANT } from '../../utils/enums';

export const TransactionDialog = () => {
  const classes = useStyles();

  const { open, variant, txnHash, hideModalF } = useModal();

  const link_JSX = (
    <Link className={classes.linkTrans} target='_blank' href={ETHERSCAN_TX_BASE_URL + txnHash}>
      <LinkOutlined style={{ marginRight: 10 }} /> View on Etherscan
    </Link>
  );

  return (
    <Dialog PaperProps={{ className: classes.dialogPaperTrans }} open={open} onClose={hideModalF}>
      <DialogContent
        style={{
          padding: 10,
        }}
      >
        <Container disableGutters maxWidth='xs'>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            spacing={2}
            style={{
              minHeight: '300px',
              height: '400px',
              borderRadius: 10,
            }}
          >
            <Box style={{ position: 'absolute', right: '5%', top: '5%' }}>
              <CloseButton onClick={hideModalF} />
            </Box>

            {variant === MODAL_VARIANT.LOADING && (
              <>
                <Grid item>
                  <CircularProgress size={150} thickness={0.5} color='primary' />
                </Grid>
                <Grid item>
                  <Typography style={{ textAlign: 'center' }}>
                    Please wait for confirmation
                  </Typography>
                </Grid>
                {txnHash && link_JSX}
              </>
            )}

            {variant === MODAL_VARIANT.SUCCESS && (
              <>
                <Grid item>
                  <Check className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your transaction has been confirmed</Typography>
                </Grid>
                {txnHash && link_JSX}
              </>
            )}
            {variant === MODAL_VARIANT.FAILED && (
              <>
                <Grid item>
                  <Remove className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your transaction has been failed</Typography>
                </Grid>
              </>
            )}

            {variant === MODAL_VARIANT.BLOCKED && (
              <>
                <Grid item>
                  <Block className={classes.check} />
                </Grid>
                <Grid item>
                  <Typography>Your transaction has been rejected</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionDialog;
