import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  snackbarStyles: {
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
    color: theme.palette.text.primary,
    border: 'none',
    '& .MuiAlert-icon': {
      color: theme.palette.primary.main,
    },
    '& .MuiAlert-message': {
      fontSize: '12px',
      letterSpacing: 2,
    },
    minWidth: '250px',
  },
  etherscan: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: '4px',
    },
  },
}));
