import { Tooltip, TooltipProps } from '@material-ui/core';

import useStyles from './styles';

interface ICTooltip {
  title: string;
  children: JSX.Element;
}

const CTooltip: React.FC<ICTooltip & TooltipProps> = ({ title, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Tooltip {...rest} title={title} classes={{ tooltip: classes.tooltip }} arrow>
      {children}
    </Tooltip>
  );
};

export default CTooltip;
