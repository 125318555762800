import ApplicationUpdater from '../state/application/updater';
import BurnUpdater from '../state/burn/updater';
import TransactionUpdater from '../state/transaction/updater';

const Updater = () => {
  return (
    <>
      <ApplicationUpdater />
      <BurnUpdater />
      <TransactionUpdater />
    </>
  );
};

export default Updater;
