import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useUserTransactions } from './hooks';

const Updater = (): null => {
  const { account } = useWeb3React();
  const { getUserTransactions } = useUserTransactions();

  useEffect(() => {
    if (account) {
      getUserTransactions(account);
    }
  }, [account]);

  return null;
};

export default Updater;
