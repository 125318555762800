import BN from 'bignumber.js';
import { fetchTokensDollarValue, IDollarValue } from '.';
import { indexFundClient as client } from '../apollo/client';
import { INDEX_FUND_OVERALL_DATA, INDEX_FUND_TVL_DATA } from '../apollo/queries';
import { logError } from '../utils/logs';
import { DEFAULT_DOLLAR_VALUE } from '../constants';

interface IndexFundOverallData {
  indexTokens: {
    id: string;
    symbol: string;
    name: string;
    decimals: string;
    currentIndexAmount: string;
  }[];

  pilotBurneds: {
    totalPilotBurned: string;
  }[];

  pilotBurnedTransactions: {
    pilotBurned: string;
  }[];
}

export const fectIndexFundOverallData = async () => {
  try {
    const { data, error, loading } = await client.query<IndexFundOverallData>({
      query: INDEX_FUND_OVERALL_DATA,
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      return data;
    }
    logError('fectIndexFundOverallData-e', error);
  } catch (e) {
    logError('fectIndexFundOverallData', e);
  }
};

interface IIndexFundTVL {
  tokenChartTransactions: [
    {
      indexAmount: string;
      timestamp: string;
      indexTokenId: {
        id: string;
      };
    }
  ];
}

export const fetchIndexFundTVL = async () => {
  const indexFundDeployed = 18896;

  try {
    const { data, error, loading } = await client.query<IIndexFundTVL>({
      query: INDEX_FUND_TVL_DATA,
      fetchPolicy: 'no-cache',
    });

    if (!loading && data) {
      let tokenIds = getTokenIds(data);
      let tokensUSDAmount = await getUSDAmount(tokenIds);

      let formated = formatData(data, tokensUSDAmount ?? {});

      let chart = forwardFill(indexFundDeployed - 1, formated);

      return chart;
    }
    logError('fetchIndexFundTVL-e', error);
  } catch (e) {
    logError('fetchIndexFundTVL', e);
  }
};

const getTokenIds = (data: IIndexFundTVL) => {
  let tokenIds: string[] = [];

  data.tokenChartTransactions.forEach(t => {
    if (!tokenIds.includes(t.indexTokenId.id)) tokenIds.push(t.indexTokenId.id);
  });

  return tokenIds;
};

const getUSDAmount = async (tokenIds: string[]) => await fetchTokensDollarValue(tokenIds);

const formatData = (data: IIndexFundTVL, usd: IDollarValue) => {
  let tokenAmount = {};

  data.tokenChartTransactions.forEach(d => {
    let key = Math.ceil(parseInt(d.timestamp) / (60 * 60 * 24));
    const usdPrice = usd[d.indexTokenId.id];
    const dollarValue = new BN(usdPrice ? usdPrice.usd : DEFAULT_DOLLAR_VALUE);
    const indexAmountUSD = new BN(d.indexAmount).times(dollarValue);

    //@ts-ignore
    if (tokenAmount[key]) {
      //@ts-ignore
      let comingAmount = indexAmountUSD.plus(tokenAmount[key].amount);

      //@ts-ignore
      tokenAmount[key] = {
        timestamp: parseInt(d.timestamp) * 1000,
        //@ts-ignore
        amount: comingAmount,
      };
    } else {
      //@ts-ignore
      tokenAmount[key] = {
        timestamp: parseInt(d.timestamp) * 1000,
        //@ts-ignore
        amount: indexAmountUSD,
      };
    }
  });

  return tokenAmount;
};

const forwardFill = (startDay: number, tokenAmount: any): number[][] => {
  let endDay = Math.ceil(Date.now() / (1000 * 60 * 60 * 24));
  let totalAmount = new BN(0);

  let chartDay: [number, BN][] = [];

  for (let i = startDay; i <= endDay; i++) {
    //@ts-ignore
    if (tokenAmount[i]) {
      totalAmount = totalAmount.plus(tokenAmount[i].amount);
      chartDay.push([tokenAmount[i].timestamp, totalAmount]);
    } else {
      chartDay.push([i * 1000 * 60 * 60 * 24, totalAmount]);
    }
  }

  return chartDay.map((chart: [number, BN]) => {
    return [chart[0], chart[1].toNumber()];
  });
};
