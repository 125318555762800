import { PILOT_LOGO, ZERO_ADDRESS, PILOT_ADDRESS, DEV_ENVIRONMENT } from './';
import { IToken } from '../utils/generalTypes';

export const PILOT: IToken = {
  //address: DEV_ENVIRONMENT ? '0x006FAb5bce63d45bE47B12010F9782dc161b8fF1' : PILOT_ADDRESS,
  address: DEV_ENVIRONMENT ? '0xc9F2964c57Bb34FaF41392f914d629933FAF80e0' : PILOT_ADDRESS,
  name: 'Unipilot',
  symbol: 'PILOT',
  decimals: 18,
  logoURI: PILOT_LOGO,
};

export const ETH: IToken = {
  address: ZERO_ADDRESS,
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
  logoURI: 'https://gateway.pinata.cloud/ipfs/QmeKqk6KjLejuCeQY9q6DyM4CvKS3DrRtpKv1EE8anKNip',
  isETH: true,
};

export const DEFAULT_TOKEN: IToken = {
  address: '',
  name: '',
  symbol: '',
  decimals: 0,
};
