import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { IndexFundWithCountDown } from '../pages/index';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={IndexFundWithCountDown} />
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
