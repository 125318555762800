export enum TRANSACTION_STATUS {
  PENDING = 'pending',
  SUCCESS = 'Success',
  FAILED = 'Failed',
}

export enum SNACKBAR_SEVERITY {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'info',
}

export enum MODAL_VARIANT {
  LOADING = 'Loading',
  SUCCESS = 'Success',
  BLOCKED = 'Blocked',
  FAILED = 'Failed',
}

export enum LOADING {
  APPROVE = 'Approving',
  BURN = 'Burning',
}
